import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { railsFetch } from "src/utils/http"

export default class extends Controller {
  static targets = ['input', 'form']
  static values = {
    url: String
  }

  connect() {
    const div = document.createElement('div')
    div.innerText = I18n.t('errors.messages.taken')
    div.classList.add('invalid-feedback')
    this.inputTarget.after(div)
  }

  inputTargetConnected(target) {
    this.initState = target.value;

    target.addEventListener('change', this.inputChanged )
    target.addEventListener('keyup', this.inputChanged )
  }

  url() {
    return this.urlValue + '/' + this.inputTarget.value
  }

  inputChanged = () => {
   this.inputTarget.classList.remove('is-invalid')
  }

  disconnect() {
    this.inputTargets.forEach(input => {
      input.removeEventListener('change', this.inputChanged)
      input.removeEventListener('keyup', this.inputChanged)
    })
  }

  async submit(event) {
    if (this.initState !== this.inputTarget.value && this.inputTarget.value !== '') {
      event.preventDefault()

      const response = await railsFetch(this.url(), {
        method: 'GET'
      })

      // ok -> did already used, thus invalid
      if (response.ok) {
        this.inputTarget.classList.add('is-invalid')

        Rails.enableElement(this.formTarget)
      } else {
        event.target.submit()
      }
    }
  }
}
