import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['orderOption', 'qty']
  static values = { device: String, currency: String }

  addToBasket() {
    if (
      !this.hasOrderOptionTarget ||
      !this.hasQtyTarget ||
      !this.hasDeviceValue ||
      typeof window.dataLayer === 'undefined'
    ) return

    const payloadData = JSON.parse(this.deviceValue)
    const { id, name, category, renting_price, purchase_price } = payloadData

    const quantity = this.qtyTarget.value
    const variant = this.getOrderVariant()
    const price = this.getPriceForVariant(renting_price, purchase_price, variant)

    const gtm_payload = {
      name,
      id,
      price,
      brand: 'Cisco',
      category,
      variant,
      quantity
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: this.currencyValue,
        add: { products: [gtm_payload] }
      }
    })
  }

  getPriceForVariant(renting_price, purchase_price, variant) {
    if (variant === 'purchase' && purchase_price) return purchase_price
    if (variant === 'renting' && renting_price) return renting_price
  }

  getOrderVariant() {
    return this.orderOptionTargets.find(orderOption => orderOption.checked)?.dataset.purchaseType
  }
}
