import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['orderOption', 'buttonPane', 'total', 'totalInfo', 'qty', 'qtyText', 'intervalText', 'primaryAddToBasketButton']
  static values = {
    currency: String
  }

  connect() {
    this.oldBodyPadding = document.body.style.paddingBottom || undefined

    this.orderOptionTargets.forEach((inp) => {
      inp.addEventListener('change', (ev) => this.onOrderOptionChanged(ev.target))
    })

    const selectedInput = this.selectedOrderOption()
    if (selectedInput) {
      this.onOrderOptionChanged(selectedInput)
    }

    this.observer = new IntersectionObserver(
      this.onButtonVisibilityChange,
      {root: null, rootMargin: `-40px`, threshold: 1.0}
    )

    if (this.hasPrimaryAddToBasketButtonTarget)
      this.observer.observe(this.primaryAddToBasketButtonTarget)

    // Pre-select according to fragment or default to purchase
    const fragment = window.location.hash?.substr(1) || 'purchase'
    this.orderOptionTargets.forEach((inp) => {
      if (inp.dataset.purchaseType === fragment) {
        inp.checked = true
        inp.dispatchEvent(new Event('change'))
      }
    })
  }

  getButtonPaneTargetHeight() {
    return `${this.buttonPaneTarget.getBoundingClientRect().height}px`
  }

  onOrderOptionChanged(input) {
    if (this.selectedOrderOption()) {
      this.showTotalInfoFor(input.value)
      this.updateTotal()
      this.handleIntervalDisplay()
    } else {
    }
  }

  onButtonVisibilityChange = event => {
    if (event[0].isIntersecting) {
      this.hideButtonPane()
    } else {

      this.showButtonPane()
    }
  }

  showButtonPane() {
    document.body.style.paddingBottom = this.getButtonPaneTargetHeight()
    this.buttonPaneTarget.style.bottom = '0'
  }

  hideButtonPane() {
    document.body.style.paddingBottom = this.oldBodyPadding
    this.buttonPaneTarget.style.bottom = '-' + this.getButtonPaneTargetHeight()
  }

  formatPrice(price) {
    return new Intl.NumberFormat(document.documentElement.lang, {
      style: 'currency', currency: this.currencyValue
    }).format(price)
  }

  showTotalInfoFor(value) {
    this.totalInfoTargets.forEach((info) => {
      info.style.display = info.getAttribute('data-show-if') === value ? 'block' : 'none'
    })
  }

  selectedOrderOption() {
    return this.orderOptionTargets.find((x) => x.checked)
  }

  updateTotal() {
    const total = this.calcTotal()
    this.totalTarget.innerHTML = total && this.formatPrice(total) || ''
    this.qtyTextTarget.innerHTML = Number(this.qtyTarget.value) + ' ×'
  }

  handleIntervalDisplay() {
    const selected = this.selectedOrderOption()
    if (!selected || !this.hasIntervalTextTarget) return

    const isRenting = selected.getAttribute('data-purchase-type') === 'renting'
    this.intervalTextTarget.style.display = isRenting ? 'block' : 'none'
  }

  calcTotal() {
    const selected = this.selectedOrderOption()
    if (!selected) return

    const qty = Number(this.qtyTarget.value)
    const priceCents = Number(selected.getAttribute('data-price-cents'))

    return qty * priceCents / 100
  }
}
