import Noty from "noty";

Noty.overrideDefaults({
  layout: 'topRight',
  theme: 'sunset',
  timeout: 5000,
  container: '#noty_layout__topRight',
  callbacks: {
    onTemplate: function () {
      let titleHTML = (this.options.title) ? `<div class="title">${this.options.title}</div>` : '';
      let html = `${titleHTML}${this.barDom.innerHTML}`

      this.barDom.innerHTML = html
    }
  }
});

document.addEventListener('turbo:load', () => {
  const defaultType = 'alert';
  const availableTypes = [
    'alert', 'success', 'warning', 'error', 'info', 'information',
  ];
  document.querySelectorAll('#flash-messages .flash').forEach(function (msg) {
    let type = msg.dataset.type;
    if (availableTypes.indexOf(type) === -1) {
      type = defaultType;
    }
    const text = msg.querySelector('.message').innerText;
    new Noty({
      type,
      text,
    }).show();
  });
});
