import { Controller } from "@hotwired/stimulus"
import I18n from "i18n-js"

export default class extends Controller {
  static values = {
    setupIntentId: String,
    returnUrl: String,
  }

  static targets = [
    'errorContainer',
    'form',
    'submit'
  ]

  enableSubmit() {
    this.submitTarget.removeAttribute('disabled')
    this.submitTarget.classList.remove('disabled')
  }

  disableSubmit() {
    this.submitTarget.setAttribute('disabled', '')
    this.submitTarget.classList.add('disabled')
  }

  async submit(ev) {
    ev.preventDefault()

    this.disableSubmit()
    this.hideError()

    try {
      const formData = new FormData(this.formTarget)
      const method = this.formTarget.getAttribute('method') || 'POST'
      const url = this.formTarget.getAttribute('action')

      if (!this.formTarget.reportValidity()) {
        this.enableSubmit()
        return
      }

      const response = await fetch(
        url,
        {
          method,
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        }
      )

      if (!response.ok) {
        const message = I18n.t('javascript.global.generic_error')
        this.displayError(message)
        this.enableSubmit()
        return
      }
      const payment_method_data = await response.json()

      const returnUrl = new URL(this.returnUrlValue)
      returnUrl.searchParams.append('setup_intent', this.setupIntentIdValue)
      window.location = returnUrl

    } catch(e) {
      console.error(e)
      this.enableSubmit()
    }
  }

  hideError() {
    this.errorContainerTarget.textContent = ''
    this.errorContainerTarget.classList.add('hidden')
  }

  displayError(error) {
    this.errorContainerTarget.textContent = error
    this.errorContainerTarget.classList.remove('hidden')
  }
}
