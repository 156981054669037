import { Controller } from "@hotwired/stimulus"
import { KeyboardCodes, KeyCodeEventListener } from "../utils/keyboard"
import debounce from "lodash/debounce"
import uniq from "lodash/uniq"
import flatpickr from "flatpickr"



function setFormValues(form, name, values) {
  const element = form.querySelector(`[name="${name}"]`)
  if (!element) return

  if (element instanceof HTMLInputElement) {
    if (element['_flatpickr']) {
      element.value = values[0] || element.dataset.defaultValues
      element.dataset.defaultValues = element.value

      const formatted_value = element.value.split('/')
      const datepicker = element['_flatpickr']

      if (formatted_value.length < 2 && element.dataset.asRange != 'true') return

      datepicker.setDate(formatted_value, false)
    } else {
      element.value = values[0] || ""
    }
  } else if (element instanceof HTMLSelectElement) {
    element.querySelectorAll("option").forEach((option) => option.selected = false)

    const valuesToSet = element.multiple ? values : values.slice(0, 1)

    valuesToSet.forEach((v) => {
      (element.querySelector(`option[value="${v}"]`)).selected = true
    })

    element['tomSelect']?.sync()
  }
}

export default class extends Controller {
   _keyCodeListener = new KeyCodeEventListener(this, {
    [KeyboardCodes.Escape]: this.onEscape,
    [KeyboardCodes.EverythingElse]: this.onTyping,
  })
  static values = {
     useCurrentUrl: Boolean
  }

  get input() {
    return this.element.querySelector("input")
  }

  get form() {
    return this.input.closest("form")
  }

  get frame() {
    const frameId = this.form.dataset.turboFrame
    return frameId ? document.querySelector(`#${frameId}`) : null
  }

  get searchInput() {
    return this.form.querySelector('input[type="search"]')
  }

  submitForm = debounce(() => {
    if (this.useCurrentUrlValue) {
      this.form.action = window.location.href
    }
    this.form.requestSubmit()
  }, 300)

  frameLoaded = (ev) => {
    // Since we're not targeting the _top frame, we have to update the browser URL manually
    // Let's also remove the ugly empty params
    const url = new URL(ev.detail.fetchResponse.location)

    for (const key of Array.from(url.searchParams.keys())) {
      if (!url.searchParams.get(key) || key == 'page') {
        url.searchParams.delete(key)
      }
    }

    const names = uniq([...this.form.querySelectorAll("[name]")].map((x) => x.name))

    for (const name of names) {
      const values = url.searchParams.getAll(name)
      setFormValues(this.form, name, values)
    }
    window.history.pushState({}, "", url.toString())
  }

  connect() {
    super.connect()
    document.addEventListener("keyup", this._keyCodeListener.listener)
    this.form.addEventListener("formdata", this._onFormData)
    this.frame?.addEventListener("turbo:frame-render", this.frameLoaded)
    this.searchInput?.addEventListener('input', this._onSearchInput)
  }

  disconnect() {
    super.disconnect()

    document.removeEventListener("keyup", this._keyCodeListener.listener)
    this.form.removeEventListener("formdata", this._onFormData)
    this.frame?.removeEventListener("turbo:frame-render", this.frameLoaded)
    this.searchInput?.removeEventListener('input', this._onSearchInput)
  }

  onTyping() {
    this.submitForm()
  }

  onEscape() {
    if (this.input.value.trim() == "")
      return

    this.input.value = ""
    this.submitForm()
  }

  _onFormData = (evt) => {
    const formData = evt.formData

    if (this.useCurrentUrlValue) {
      const params = new URLSearchParams(window.location.search)
      for (const [key, val] of params) {
        if (formData.has(key)) continue

        formData.append(key, val)
      }
    }
  }

  _onSearchInput = (evt) => {
    // this is true when someone hits the native x on search button
    if (!evt.currentTarget.value && !evt.inputType) {
      this.onTyping()
    }
  }
}
