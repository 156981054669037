import { Controller } from "@hotwired/stimulus"
import I18n from "i18n-js"
import * as Sentry from "@sentry/browser"

export default class extends Controller {
  static values = {
    clientSecret: String,
    publishableKey: String,
    returnUrl: String,
  }

  static targets = [
    'paymentElement',
    'errorContainer',
    'form',
    'submit'
  ]

  connect() {
    this._setupStripe()
    this._createPaymentElement()
  }

  _createPaymentElement() {
    this.stripePaymentElement = this.stripeElements.create('payment', {
      fields: {
        billingDetails: {
          address: {
            postalCode: 'never',
            country: 'never'
          }
        }
      }
    })
    this.stripePaymentElement.mount(this.paymentElementTarget)

    this.stripePaymentElement.on('change', (ev) => {
      if (ev.complete) {
        this.enableSubmit()
      } else {
        this.disableSubmit()
      }
    })
  }

  enableSubmit() {
    this.submitTarget.removeAttribute('disabled')
    this.submitTarget.classList.remove('disabled')
  }

  disableSubmit() {
    this.submitTarget.setAttribute('disabled', '')
    this.submitTarget.classList.add('disabled')
  }

  _setupStripe() {
    this.stripe = Stripe(this.publishableKeyValue)
    this.stripeElements = this.stripe.elements({
      clientSecret: this.clientSecretValue,
    })
  }

  async submit(ev) {
    ev.preventDefault()

    this.disableSubmit()
    this.hideError()

    try {
      const formData = new FormData(this.formTarget)
      const method = this.formTarget.getAttribute('method') || 'POST'
      const url = this.formTarget.getAttribute('action')

      if (!this.formTarget.reportValidity()) {
        this.enableSubmit()
        return
      }

      const response = await fetch(
        url,
        {
          method,
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        }
      )

      if (!response.ok) {
        const message = I18n.t('javascript.global.generic_error')
        this.displayError(message)
        this.enableSubmit()
        return
      }
      const payment_method_data = await response.json()

      const {error} = await this.stripe.confirmSetup({
        elements: this.stripeElements,
        confirmParams: {
          return_url: this.returnUrlValue,
          payment_method_data
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        // TODO: (matheise) keep for a few weeks to see what kind of errors occur
        Sentry.captureMessage(error.message, { extra: error })
        this.displayError(error.message)
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch(e) {
      Sentry.captureException(e)
      console.error(e)
      this.enableSubmit()
    }
  }

  hideError() {
    this.errorContainerTarget.textContent = ''
    this.errorContainerTarget.classList.add('hidden')
  }

  displayError(error) {
    this.errorContainerTarget.textContent = error
    this.errorContainerTarget.classList.remove('hidden')
  }
}
