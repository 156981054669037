import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    'fallbackTimer': Number,
    'status': String
  }

  connect() {
    this.setFallbackTimer()
  }

  statusValueChanged(value, previousValue) {
    if (value === previousValue) return

    if (['provisioned', 'error', 'provisioned_with_errors'].includes(value)) {
      // We delay the reload of the page to prevent a race condition with the interactor assigning the license...
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
  }

  disconnect() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.fallbackTimer) {
      clearTimeout(this.fallbackTimer)
    }
  }

  setFallbackTimer() {
    this.fallbackTimer = setTimeout(() => {
      window.location.reload()
    }, this.fallbackTimerValue * 1000)
  }
}
