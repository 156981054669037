import Selectize from '@selectize/selectize'

// This is broken in Selectize 0.14.0, patch is already in main branch,
// but we can't wait for release.
// When you remove me, search all the application.js files for imports.
Selectize.prototype.open = function() {
  var self = this;

  if (
    self.isLocked ||
    self.isOpen ||
    (self.settings.mode === "multi" && self.isFull())
  )
    return;
  self.focus();
  self.isOpen = true;
  self.refreshState();
  self.$dropdown.css({ visibility: 'hidden', display: 'block' });
  self.setupDropdownHeight();
  self.positionDropdown();
  self.$dropdown.css({visibility: 'visible'});
  self.trigger('dropdown_open', self.$dropdown);
};
