import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { createPopper } from "@popperjs/core"

export default class OverlayMenuController extends Controller {
  visible = false
  static targets = ['trigger', 'overlay']
  static values = {parentSelector: String}

  get overlays() {
    window.overlayMenus = window.overlayMenus || new Set()
    return window.overlayMenus
  }

  connect() {
    this.overlays.add(this)

    this.createEdge()

    this.popper = createPopper(this.triggerTarget, this.overlayTarget, {
      placement: "bottom",
      modifiers: [
        {
          name: "arrow",
          options: {
            element: this.edge,
            offset: [0, 2],
          },
        },
        {
          name: "offset",
          options: {
            offset: () => [0, this.edge.getBoundingClientRect().height],
          },
        },
      ],
    })

    this.adjustPosition()
    this.applyState()

    window.addEventListener("resize", this.adjustPosition)
    window.addEventListener('turbo:render', this.applyState)
    window.addEventListener('turbo:frame-render', this.applyState)

    this.triggerTarget.addEventListener("mouseenter", this.showOverlay)
    this.triggerTarget.addEventListener("mouseleave", this.hideOverlay)
    this.overlayTarget.addEventListener("mouseenter", this.showOverlay)
    this.overlayTarget.addEventListener("mouseleave", this.hideOverlay)
    this.triggerTarget.addEventListener("touchend", this.toggleOverlay)

    this.element.addEventListener("shop:nav-item-badge", this.showBadge)
  }

  showBadge = (evt) => {
    this.element.classList.toggle("overlay-menu--with-badge", evt.detail.show)
  }

  disconnect() {
    this.overlays.delete(this)
    window.removeEventListener("resize", this.adjustPosition)
    window.removeEventListener('turbo:render', this.applyState)
    window.removeEventListener('turbo:frame-render', this.applyState)
  }

  applyState = () => {
    this.overlayTarget.classList.toggle("hidden", !this.visible)
    this.adjustPosition()
    this.adjustPosition.flush()
  }

  toggleOverlay = (ev) => {
    if (ev) ev.preventDefault()
    if (this.visible) {
      this.hideOverlay()
      this.hideOverlay.flush()
    } else {
      this.showOverlay()
    }
  }

  hideOverlayNow() {
    this.visible = false
    this.applyState()
  }

  hideOverlay = debounce(() => {
    this.hideOverlayNow()
  }, 300, { trailing: true })

  showOverlay = () => {
    this.hideOverlay.cancel()
    this.visible = true
    this.applyState()

    this.overlays.forEach((other) => {
      if (this === other) return

      other.hideOverlayNow()
    })
  }

  adjustPosition = debounce(() => {
    if (!this.visible) return

    this.popper.update()
  }, 300, { trailing: true })

  createEdge() {
    this.edge = document.createElement("div")
    this.edge.classList.add("overlay-menu__edge")
    this.overlayTarget.prepend(this.edge)
  }
}
