import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    timeout: Number
  }

  connect() {
    if (this.hasTimeoutValue) {
      setTimeout(() => window.location.reload(), this.timeoutValue);
    } else {
      window.location.reload();
    }
  }
}
