import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    event: String,
    args: Object
  }

  connect() {
    this.element.addEventListener('click', this.onClick)
  }

  onClick = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    const args = this.hasArgsValue ? this.argsValue : {}
    this.element.dispatchEvent(new CustomEvent(this.eventValue, {
      bubbles: true,
      detail: {...args, originalEvent: evt}
    }))
  }
}
