import { Controller } from '@hotwired/stimulus'
import { currencyFormatter } from "src/utils/money"

export default class extends Controller {
  static values = {
    currency: String
  }

  static targets = [
    'amountInput',
    'packageInput',
    'radioInput',
    'submit',
    'submitTooltip',
    'totalLabel',
    'toggleable'
  ]

  connect() {
    this.changeValue();
    this.updateSubmit();
  }

  changeValue = (_event) => {
    if (this.hasTotalLabelTarget) {
      let total = 0;

      this.amountInputTargets.forEach(amountInput => {
        total += amountInput.value * amountInput.dataset.price
      })

      this.packageInputTargets.forEach(packageInput => {
        if (packageInput.dataset['shop-PackageSelectionInfoValue']) {
          total += JSON.parse(packageInput.dataset['shop-PackageSelectionInfoValue']).find((element) =>
            element["name"] == packageInput.getElementsByTagName('option')[0].value
          )["price"]
        }
      })

      this.totalLabelTarget.innerHTML = currencyFormatter(this.currencyValue).format(total)
    }
  }

  updateSubmit = (_event) => {
    if (this.hasSubmitTarget && this.hasSubmitTooltipTarget) {
      let inputChanged = false;
      inputChanged = this.amountInputTargets.some((inpt) => inpt.value != inpt.dataset.initialState);

      if (!inputChanged) {
        inputChanged = this.packageInputTargets
                           .map((inpt) => inpt.querySelector('select.select'))
                           .some((slct) => slct.querySelector('option[selected=selected]').value != slct.dataset.initialState)
      }

      if (inputChanged) {
        this.submitTarget.removeAttribute('disabled');
        delete this.submitTooltipTarget.dataset.toggle;
        delete this.submitTooltipTarget.dataset.bsOriginalTitle;
      } else {
        this.submitTarget.disabled = true;
        this.submitTooltipTarget.dataset.toggle = "tooltip";
        this.submitTooltipTarget.dataset.bsOriginalTitle = this.submitTooltipTarget.dataset.tooltipTitle;
      }
    }
  }

  toggleDependencies = (data) => {
    if (this.packageInputTarget.dataset['shop-PackageSelectionInfoValue']) {
      let selectedName = JSON.parse(this.packageInputTarget.dataset['shop-PackageSelectionInfoValue']).find((element) =>
        element["name"] == data.target.value
      )['technical_name'];

      this.toggleableTargets.forEach((e) => e.classList.toggle("hidden", (selectedName != e.dataset.toggleableName)));
    }
  }

  uncheckRadio = (event) => {
    this.radioInputTargets.forEach(radioInpt => {
      if (radioInpt != event.currentTarget) {
        radioInpt.checked = false;
      }
    });
  }
}
