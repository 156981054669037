import country_selection_controller from "../components/country_selection_controller"

export const KeyboardCodes = {
  Escape: "Escape",
  ArrowUp: "ArrowUp",
  ArrowDown: "ArrowDown",
  EverythingElse: "",
}

export class KeyCodeEventListener {
  constructor(
    context,
    listeners,
  ) {
    this._context = context
    this._listeners = listeners
  }

  listener = (event) => {
    const listener = this._listeners[event.code] ?? this._listeners[KeyboardCodes.EverythingElse]

    if (listener) {
      listener.call(this._context, event)
    }
  }
}
