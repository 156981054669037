import { TooltipController } from "./tooltip_controller"
import { Application, defaultSchema } from "@hotwired/stimulus"

export function bootstrap5Stimulus() {
  const application = Application.start(window.document.documentElement, {
    ...defaultSchema,
    controllerAttribute: "data-bs-toggle",
  })

  application.load({
    identifier: "tooltip",
    controllerConstructor: TooltipController,
  })

  return application
}
