import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["animationData", "container"]
  static values = {
    loop: String,
    autoplay: String,
    showLastFrame: String
  }

  async connect() {
    const lottieModule = import(/* webpackChunkName lottie */ 'lottie-web')
    const lottie = (await lottieModule).default

    const animation = lottie.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      loop: this.loopValue !== 'false',
      autoplay: this.autoplayValue !== 'false',
      animationData: JSON.parse(this.animationDataTarget.innerHTML),
    });

    if (this.showLastFrameValue === 'true') {
      animation.addEventListener('DOMLoaded', () => {
        const endFrame = animation.totalFrames - 1;
        animation.goToAndStop(endFrame, true);
      });
    }
   }
}
