import { Controller } from "@hotwired/stimulus"
import { Dropdown } from "bootstrap5"

export default class extends Controller {
  static values = {
    "offset": String,
    "boundary": String,
  }

  connect() {
    this._dropdown = new Dropdown(this.element.querySelector(".dropdown-toggle"), {
      offset: this.offsetValue,
      boundary: this.boundary,
    })
  }

  disconnect() {
    this._dropdown.dispose()
  }

  get boundary() {
    return this.boundaryValue ? this.boundaryValue : "scrollParent"
  }
}
