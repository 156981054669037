export function createStreamReplaceMessage(targets, content) {
  const turboAction = document.createElement("turbo-stream")
  turboAction.setAttribute("action", "replace")
  turboAction.setAttribute("targets", targets)

  const template = document.createElement("template")
  template.innerHTML = content
  turboAction.append(template)

  return turboAction.outerHTML
}
