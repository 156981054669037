import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap5'

export default class extends Controller {
  static values = {
    youtube: Boolean,
    url: String
  }

  connect() {
    this.modal = this.element.querySelector('.modal')
    window.addEventListener('resize', this.adjustSize)
    this.player = this.element.querySelector('.player')
    this.modal.addEventListener("hidden.bs.modal", () => {
      this.close()
    })

    this.iframe = this.element.querySelector('iframe')
  }

  open() {
    if (this.urlValue) {
      this.iframe.src = this.urlValue
    }
    this.modalInstance = new Modal(this.modal, { backdrop: false })
    this.modalInstance.show()
  }

  close() {
    if (this.urlValue) {
      this.iframe.src = ''
    }
    this.modalInstance.hide()

    if (this.youtubeValue) {
      this.player.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    } else {
      const source = this.player.src
      this.player.src = null
      this.player.src = source
    }
  }
}
