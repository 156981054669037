import { Controller } from "@hotwired/stimulus";
import { turboStreamFetch } from "src/utils/http"

export default class extends Controller {
  static values = {
    url: String
  }

  static targets = ["startButton", "stopButton", "timer", "file"];

  connect() {
    this.stream = null;
    this.recorder = null;
    this.audioChunks = [];

    this.stopButtonTarget.classList.toggle('hidden', true);
  }

  disconnect() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  }

  async startRecording(e) {
    e.preventDefault();

    if (!navigator.mediaDevices || !window.MediaRecorder) {
      alert("Your browser does not support audio recording.");
      return;
    }

    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.recorder = new MediaRecorder(this.stream);
    this.recorder.ondataavailable = (event) => this.audioChunks.push(event.data);
    this.recorder.onstop = () => this.uploadAudio();
    this.recorder.onstart = () => {
      if(this.hasTimerTarget) {
        this.started = Date.now() / 1000
        this.timer   = setInterval(this.updateTimer.bind(this), 1000)
      }
    }
    this.recorder.start();

    this.startButtonTarget.classList.toggle('hidden', true);
    this.stopButtonTarget.classList.toggle('hidden', false);
  }

  updateTimer() {
    var date = new Date(0);
    date.setSeconds(this.elapsedTime());
    var timeString = date.toISOString().substr(14,5);

    this.timerTarget.innerHTML = timeString
  }

  elapsedTime() {
    return Date.now() / 1000 - this.started;
  }

  stopRecording(e) {
    e.preventDefault();

    this.recorder.stop();
    this.stream.getTracks().forEach(track => track.stop());

    this.startButtonTarget.classList.toggle('hidden', false);
    this.stopButtonTarget.classList.toggle('hidden', true);
  }

  uploadAudio() {
    const audioBlob = new File(this.audioChunks, Math.floor(Date.now() / 1000) + '.wav', { type: 'audio/wav' });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(audioBlob);

    this.fileTarget.files = dataTransfer.files;
    this.form.action = this.urlValue

    Rails.fire(this.form, 'submit')

    this.audioChunks = [];
  }

  get form() {
    return this.element.closest('form')
  }

}
