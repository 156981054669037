import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import "@selectize/selectize"

export default class extends Controller {
  static targets = ["selectize"]

  connect() {
    this.selectizeTargets.forEach((selectizeTarget) => this.setupSelectize(selectizeTarget))
  }

  disconnect() {
    this.selectizeTargets.forEach((selectizeTarget) => $(selectizeTarget)[0].selectize.destroy())
  }

  setupSelectize = (target) => {
    const selectizeConfig = {
      delimiter: ",",
      persist: false,
      createOnBlur: true,
      onChange: () => target.dispatchEvent(new Event("change")),
      create: (value) => ({ value, text: value }),
    }
    if (target.dataset.selectizeFilter) {
      selectizeConfig["createFilter"] = new RegExp(target.dataset.selectizeFilter, "i")
    }
    if (target.dataset.selectizeMultiple) {
      selectizeConfig["plugins"] = ["remove_button"]
    }
    if (target.dataset.selectizePreventCreate === "true") {
      selectizeConfig["create"] = false
    }
    if (target.dataset.selectizeLoadUrl) {
      selectizeConfig["valueField"] = 'id'
      selectizeConfig["labelField"] = 'name'
      selectizeConfig["searchField"] = 'name'
      selectizeConfig["load"] = function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
            url: target.dataset.selectizeLoadUrl,
            type: 'GET',
            dataType: 'json',
            data: {
              q: query
            },
            error: function() {
              callback();
            },
            success: function(res) {
              callback(res);
            }
        });
      }
    }

    $(target).selectize(selectizeConfig)
  }
}
