import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap5"

export class TooltipController extends Controller {

  connect() {
    this._tooltip = new Tooltip(this.element)
  }

  disconnect() {
    this._tooltip.dispose()
  }
}
