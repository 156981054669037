import { Controller } from '@hotwired/stimulus'

function addFlash(flashesContainer, type, message) {
  const flash = flashesContainer.querySelector('script').innerHTML.replace('{{type}}', type).replace('{{message}}', message)
  const existing = flashesContainer.firstElementChild

  if (existing) {
    existing.insertAdjacentHTML("beforebegin", flash)
  } else {
    flashesContainer.innerHTML = flash
  }
}

const framePaths = ({
  'users/sign_in': 'signin',
  'users/sign_up': 'signup',
  'users/password/new': 'resetPassword',
})

export default class LoginFrameController extends Controller {
  static values = {
    accountsUrl: String,
    src: String,
  }
  static targets = [
    'iframe',
    'loadingIndicator'
  ]

  onMessage = (msg) => {
    if (this.isSameOrigin(msg) || this.isAccountsOrigin(msg)) {
      if (msg.data.status === 'success') {
        if (msg.data.target) {
          window.location = msg.data.target
        } else {
          window.location.reload()
        }
      }

      if (msg.data.path) {
        const mode = this.getModeForPath(msg.data.path)
        if (mode) {
          this.dispatchChangedEvent(mode)
        }
      }

      if (msg.data.height) {
        this.element.querySelector('iframe').style.height = msg.data.height
      }

      if (msg.data.loading !== undefined) {
        this.loadingIndicatorTarget.style.display = msg.data.loading ? 'flex' : 'none'
      }

      if (msg.data.flashes) {
        const flashesContainer = document.querySelector('.flashes')

        msg.data.flashes.forEach(([type, msg]) => {
          addFlash(flashesContainer, type, msg)
        })
      }
    }
  }

  dispatchChangedEvent(mode) {
    this.element.dispatchEvent(new CustomEvent('login-frame:changed', {
      bubbles: true,
      detail: {mode},
    }))
  }

  getModeForPath(path) {
    const cls = Object.entries(framePaths).find((entry) => path.endsWith(entry[0]))
    return cls && cls[1]
  }

  connect() {
    window.addEventListener('message', this.onMessage)

    if (this.hasIframeTarget) {
      this.iframeTarget.src = this.srcValue
    }
  }

  disconnect() {
    window.removeEventListener('message', this.onMessage)
  }

  isAccountsOrigin(msg) {
    return new URL(msg.origin).host === new URL(this.accountsUrlValue).host
  }

  isSameOrigin(msg) {
    return new URL(msg.origin).host === window.location.host
  }
}
