import { Controller } from '@hotwired/stimulus'
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ['indicatorOpen', 'indicatorClosed', 'toggleable']
  static values = {breakpoint: String}

  connect() {
    this.activateIfMobile()

    window.addEventListener('resize', this.activateIfMobile)
  }

  activateIfMobile = debounce(() => {
    if (window.matchMedia(this.breakpointValue).matches) {
      this.active = true
      this.close()
    } else {
      this.deactivate()
    }
  }, 50)

  deactivate() {
    this.active = false
    this.indicatorClosedTargets.forEach((e) => e.classList.add('hidden'))
    this.indicatorOpenTargets.forEach((e) => e.classList.add('hidden'))
    this.toggleableTargets.forEach((e) => e.classList.remove('hidden'))
  }

  close() {
    this.opened = false
    this.indicatorOpenTargets.forEach((e) => e.classList.add('hidden'))
    this.indicatorClosedTargets.forEach((e) => e.classList.remove('hidden'))
    this.toggleableTargets.forEach((e) => e.classList.add('hidden'))
  }

  open() {
    this.opened = true
    this.indicatorClosedTargets.forEach((e) => e.classList.add('hidden'))
    this.indicatorOpenTargets.forEach((e) => e.classList.remove('hidden'))
    this.toggleableTargets.forEach((e) => e.classList.remove('hidden'))
  }

  toggle() {
    if (!this.active) return
    this.opened ? this.close() : this.open()
  }
}
