import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["input", "add"]

  connect() {
    this.toggleAdd();
  }

  toggleAdd() {
    this.addTarget.classList.toggle('invisible', this.showAdd());
  }

  showAdd() {
    return this.inputTargets.some(input => input.value.trim() == '');
  }

  drop(event) {
    if (this.inputTargets.length > 1) {
      event.target.closest('.row').remove();
    } else {
      this.inputTarget.value = '';
      this.inputTarget.focus()
    }

    this.toggleAdd();
  }
}

