import { Controller } from '@hotwired/stimulus'

const googleComponents = [
  {googleComponent: 'route', target: 'street', version: 'short_name'},
  {googleComponent: 'street_number', target: 'street_no', version: 'long_name'},
  {googleComponent: 'locality', target: 'city', version: 'long_name'},
  {googleComponent: 'administrative_area_level_1', target: 'state', version: 'short_name'},
  {googleComponent: 'postal_code', target: 'zip', version: 'long_name'},
  {googleComponent: 'country', target: 'country', version: 'short_name'},
]

const formInputContainer = '.form-floating, .form-group, .form-vertical'

export default class extends Controller {
  static targets = ["address", "addressLabel", "street", "street_no", "state", "city", "zip", "extraAddressLine", "country", "wrapper", "enterManually"]
  static values = {
    countriesWithLeadingStreetNumber: Array,
    countriesWithTrailingZipCode: Array,
    disabled: Boolean
  }

  enterManually(evt) {
    this.enableTargets()
    this.showWrapperElement()
    this.hideEnterManuallyButton()
    this.hideAutocompleteField()
  }

  connect() {
    let autocomplete

    this.sortForm()

    if (this.disabledValue) {
      this.enableTargets()
      return
    }

    // Add random string to input id
    this.addressTarget.id += `_${(Math.random() + 1).toString(36).substring(7)}`


    this.injectScript().then(() => {
      let input = this.addressTarget
      let options = {
        types: ['geocode'],
        fields: ['address_component'],
      }

      const countries = this.allowedCountries()
      // google only allows the restriction for a maximum of 5 countries
      if (countries.length <= 5) {
        options['componentRestrictions'] = { country: countries }
      }

      autocomplete = new google.maps.places.Autocomplete(input, options)
      autocomplete.addListener('place_changed', () => {
        this.assignAddress(autocomplete)
      })
      input.addEventListener('keypress', () => {
        if (event.keyCode == 13) {
          event.preventDefault()
        }
      })
    }).catch(error => {
      console.log(error)
    })
  }

  allowedCountries = () =>
    [...this.countryTarget.querySelectorAll('option')].map(option => option.value).filter(Boolean)

  sortForm = () => {
    const country = this.countryTarget.value

    if (country === '') {
      return
    }

    if (this.hasStreet_noTarget) {
      if (this.countriesWithLeadingStreetNumberValue.includes(country)) {
        this.street_noTarget.closest(formInputContainer).parentElement.classList.add('order-first')
      } else {
        this.street_noTarget.closest(formInputContainer).parentElement.classList.remove('order-first')
      }
    }

    if (this.countriesWithTrailingZipCodeValue.includes(country)) {
      this.cityTarget.closest(formInputContainer).parentElement.classList.add('order-last')
    } else {
      this.cityTarget.closest(formInputContainer).parentElement.classList.remove('order-last')
    }
  }

  injectScript() {
    return new Promise((resolve, reject) => {
      let script_element = document.getElementById('googleMapsScript')

      if (script_element == null) {
        const script = document.createElement('script')
        script.setAttribute("id", "googleMapsScript")
        script.async = true
        script.defer = true
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDrIQOK3adB3STe-MloaYI4N7cjb2TWEKo&libraries=places&language=${this.language}`
        script.addEventListener('load', resolve)
        script.addEventListener('error', () => reject('Error loading script.'))
        script.addEventListener('abort', () => reject('Script loading aborted.'))
        document.head.appendChild(script)
      } else if (window.google) {
        resolve()
      } else {
        script_element.addEventListener('load', resolve)
      }
    })
  }

  assignAddress(autocomplete) {
    let place = autocomplete.getPlace()
    let addressComponents = place.address_components

    for (const component in googleComponents) {
      addressComponents.forEach((addressComponent) => this.populateAddressField(addressComponent, googleComponents[component]))
    }

    this.enableTargets()
    this.sortForm()
  }

  hideEnterManuallyButton() {
    if (!this.hasEnterManuallyTarget) return
    this.enterManuallyTarget.classList.add('hidden')
  }

  hideAutocompleteField() {
    if(!this.hasAddressTarget) return
    this.addressTarget.classList.add('hidden')

    if(this.hasAddressLabelTarget) {
      this.addressLabelTarget.classList.add('hidden')
    }
  }

  populateAddressField(addressComponent, formMap) {
    if (formMap.googleComponent === addressComponent.types[0]) {
      const formValue = addressComponent[formMap.version]
      this[formMap.target + 'Targets'].forEach(target => {
        target.value = formValue
        target.dispatchEvent(new Event('input'))
        target.dispatchEvent(new Event('change'))
      });
    }
  }

  *eachField() {
    for (const component of googleComponents) {
      const compTargetName = component.target + 'Targets'
      if (!this.targets.has(component.target)) continue

      yield this[compTargetName]
    }
  }

  enableTargets() {
    for(const fields of this.eachField()) {
      fields.forEach(field => {
        if (field instanceof HTMLSelectElement) {
          field.disabled = false
        } else {
          field.readOnly = false
        }
      })
    }

    if (this.hasExtraAddressLineTarget) {
      this.extraAddressLineTargets.forEach(field => { field.readOnly = false })
    }
  }

  showWrapperElement = () => {
    if (this.hasWrapperTarget) {
      this.wrapperTarget.classList.remove('hidden')
    }
  }

  get language() {
    return document.documentElement.lang || 'en'
  }
}
