import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    currency: String,
    initialLicenseCount: String,
  }
  static targets = [
    "licenseCountInput",
    "packageInput",
    "packageWrapper",
    "total",
    "grandTotal",
    "licenseCountSubmit",
    "submit",
    "submitTooltip",
  ]

  moneyFormatter() {
    return new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currencyValue,
      minimumFractionDigits: 2,
    });
  }

  connect() {
    this.locale = document.querySelector("html").getAttribute("lang");
    this.currency = document.querySelector("html").dataset.currency;
    if (this.targets.has("licenseCountInput")) {
      this.updateTotal();
    }
  }

  updateSubmit() {
    let isInitialState = true;

    if (this.targets.has("packageInput")) {
      isInitialState = this.packageInputTarget.dataset.initialPackageValue == this.packageInputTarget.value;
    } else if (this.targets.has("licenseCountInput")) {
      isInitialState = this.licenseCountInputTarget.dataset.initialLicenseCountValue == this.licenseCountInputTarget.value;
    }

    if (isInitialState) {
      this.submitTarget.disabled = true;
      this.submitTooltipTarget.dataset.toggle = "tooltip";
      this.submitTooltipTarget.dataset.bsToggle = "tooltip";
    } else {
      this.submitTarget.disabled = false;
      this.submitTooltipTarget.dataset.toggle = "";
      this.submitTooltipTarget.dataset.bsToggle = "";
    }
  }

  updateTotal() {
    if (this.targets.has("packageInput")) {
      this.updatePackageTotal();
    } else if (this.targets.has("licenseCountInput")) {
      this.updateLicenseCountTotal();
    }
  }

  updatePackageTotal() {
    let price = JSON.parse(
      this.packageWrapperTarget.dataset['shop-PackageSelectionInfoValue']
    ).find((element) => element["name"] == this.packageInputTarget.value)["price"]

    let formattedValue = this.moneyFormatter().format(price);

    if (this.targets.has("grandTotal")) {
      this.grandTotalTarget.textContent = this.grandTotalTarget.dataset.label.replace("%{price}", formattedValue);
    }

    if (this.targets.has("licenseCountSubmit")) {
      if (this.packageInputTarget.value === this.packageInputTarget.dataset.initialPackageValue) {
        this.licenseCountSubmitTarget.dataset.toggle = "tooltip";
        this.licenseCountSubmitTarget.disabled = true;
      } else {
        this.licenseCountSubmitTarget.dataset.toggle = "";
        this.licenseCountSubmitTarget.disabled = false;
      }
    }

    this.updateSubmit();
  }

  updateLicenseCountTotal() {
    let price = this.licenseCountInputTarget.dataset.unitPrice;
    let formattedValue = this.moneyFormatter().format((this.licenseCountInputTarget.valueAsNumber * price));
    this.totalTarget.textContent = formattedValue;

    if (this.targets.has("grandTotal")) {
      this.grandTotalTarget.textContent = this.grandTotalTarget.dataset.label.replace("%{price}", formattedValue);
    }
    if (this.targets.has("licenseCountSubmit")) {
      if (this.licenseCountInputTarget.value === this.licenseCountInputTarget.dataset.initialValue) {
        this.licenseCountSubmitTarget.dataset.toggle = "tooltip";
        this.licenseCountSubmitTarget.disabled = true;
      } else {
        this.licenseCountSubmitTarget.dataset.toggle = "";
        this.licenseCountSubmitTarget.disabled = false;
      }
    }

    this.updateSubmit();
  }
}
