import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap5"

export default class extends Controller {
  static values = {
    src: String,
    type: String,
    controls: Boolean,
  }
  static classes = ["playing"]

  disconnect() {
    this.player?.dispose()
    window.removeEventListener("resize", this.adjustSize)
  }

  connect() {
    if (!this.srcValue) return

    this.videojs = import(/* webpackChunkName: "videojs" */ "video.js")
    this.modal = this.element.querySelector(".modal")
    this.video = this.element.querySelector("video")
    this.modal.addEventListener("hide.bs.modal", () => {
      this.player.pause()
    })
    this.modalPlayButton = this.element.querySelector(".modal-dialog .play-button")
    this.modalPlayButton.addEventListener("click", (ev) => {
      ev.stopImmediatePropagation()
      ev.preventDefault()
      this.togglePlayPause()
    })
    window.addEventListener("resize", this.adjustSize)

    this.modal.addEventListener("shown.bs.modal", () => {
      if (this.player) {
        this.player.play()
      } else {
        this.setupPlayer()
      }
    })

    this._modal = new Modal(this.modal, { backdrop: false })

    this.playerOptions = this.createPlayerOptions()
  }

  createPlayerOptions() {
    return {
      autoplay: true, loop: true, controls: this.controlsValue, children: {
        controlBar: {
          currentTimeDisplay: true,
          playToggle: false,
          volumePanel: false,
          fullscreenToggle: true,
          pictureInPictureToggle: false,
        },
      },
    }
  }

  togglePlayPause() {
    if (this.player.paused()) {
      this.player.play()
    } else {
      this.player.pause()
    }
  }

  setupPlayer() {
    this.videojs.then((videojs) => {
      this.player = videojs.default(this.video, this.playerOptions, this.adjustSize)
      this.video.addEventListener("play", () => {
        this.modal.classList.add(this.playingClass)
      })
      this.video.addEventListener("pause", () => {
        this.modal.classList.remove(this.playingClass)
      })

      this.player.src({
        src: this.srcValue, type: this.typeValue,
      })
      if (this._width)
        this.player.width(this._width)
    })
  }

  adjustSize = () => {
    this._width = this.modal.querySelector(".modal-dialog").getBoundingClientRect().width

    if (this.player)
      this.player.width(this._width)
  }

  open() {
    this._modal.show()
  }

  close() {
    this._modal.hide()
  }
}
