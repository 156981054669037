import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper']

  close() {
    if (!this.hasWrapperTarget) return
    this.wrapperTarget.remove()
  }
}
