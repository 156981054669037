import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['licenses']

  showLicenses() {
    this.licensesTarget.classList.remove('hidden')
  }

  hideLicenses() {
    this.licensesTarget.classList.add('hidden')
  }
}
