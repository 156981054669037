import { Controller } from '@hotwired/stimulus'

const VISIBLE_CLASS = 'modal--visible'

export default class extends Controller {
  static targets = ['modal', 'content']
  static values = {
    autoShow: Boolean,
    redirectAfterClose: String,
    cleanupAfterClose: Boolean,
    openOn: String,
    backdropClose: Boolean
  }

  disconnect() {
    if (!this.hasContentTarget) return
    this.contentTarget.removeEventListener('click', this.stopEventPropagation)
    document.body.classList.remove('noscroll-y')

    if (this.hasOpenOnValue) {
      document.removeEventListener(this.openOnValue, this.show)
    }
  }

  connect() {
    if (this.autoShowValue)
      this.show()

    if (this.hasOpenOnValue) {
      document.addEventListener(this.openOnValue, this.show)
    }

    if (this.backdropCloseValue) {
      this.modalTarget.addEventListener('click', this.hide)
    }
  }

  hide = () => {
    this.modalTarget.classList.remove(VISIBLE_CLASS)
    document.body.classList.remove('noscroll-y')

    if (this.redirectAfterCloseValue)
      window.location = this.redirectAfterCloseValue

    if (this.cleanupAfterCloseValue) {
      let modalRoot = this.element
      while(modalRoot.parentElement.id !== 'modals') {
        modalRoot = modalRoot.parentElement
      }
      modalRoot.remove()
    }
  }

  show = () => {
    this.modalTarget.classList.add(VISIBLE_CLASS)
    document.body.classList.add('noscroll-y')

    if (this.backdropCloseValue) {
      // TODO:
      //   stopEventPropagation breaks turbo links?
      //   it prevents that the request uses the proper 'Turbo-Frame' header
      //   so on a modal new turbo links didn't work for me
      //   since stopEventPropagation came through the backdropClose feature,
      //   maybe we only do this when the feature is also enabled.
      this.contentTarget.addEventListener('click', this.stopEventPropagation)
    }

    // TODO: just use bootstrap?
    this.modalTarget.dispatchEvent(new Event('shown.bs.modal'))
  }

  stopEventPropagation(event) {
    event.stopPropagation()
  }
}
