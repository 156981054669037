import { Controller } from "@hotwired/stimulus";

const paddingElementSelectors = ["body section:first-of-type", "body > .container.grid"]
const topOffsetElementSelectors = [".navigation"]
const transitionTime = '1s'
const transitionAnimation = 'ease'
const slideInDelayInMs = 2000;

export default class extends Controller {
  static targets = ["selector"];

  connect() {
    this.selectorTarget.style.top = `-${this.selectorTarget.offsetHeight}px`;

    window.setTimeout(this.showRegionSelector, slideInDelayInMs);
  }

  showRegionSelector = () => {
    this.selectorTarget.style.transition = `top ${transitionAnimation} ${transitionTime}`;
    this.selectorTarget.style.top = 0;

    const topOffset = this.selectorTarget.offsetHeight;

    paddingElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.transition = `padding ${transitionAnimation} ${transitionTime}`;
        element.style.paddingTop = `calc(${element.style.paddingTop || '0px'} + ${topOffset}px)`;
      }
    })

    topOffsetElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.transition = `top ${transitionAnimation} ${transitionTime}`;
        element.style.top = `calc(${element.style.top || '0px'} + ${topOffset}px)`;
      }
    })
  };

  hideRegionSelector = () => {
    const topOffset = this.selectorTarget.offsetHeight;

    this.selectorTarget.style.top = `-${topOffset}px`

    paddingElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.paddingTop = `calc(${element.style.paddingTop || '0px'} - ${topOffset}px)`;
      }
    })

    topOffsetElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.top = `calc(${element.style.top || '0px'} - ${topOffset}px)`;
      }
    })

    this.element.addEventListener('transitionend', () => this.element.remove())
  }
}
