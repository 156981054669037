import { Controller } from "@hotwired/stimulus"

import { turboStreamFetch } from "src/utils/http"

export default class extends Controller {

  static values = {
    getTotalUrl: String,
  }

  get form() {
    return this.element
  }

  async updateTotal() {
    const formData = new FormData(this.form)
    await turboStreamFetch(this.getTotalUrlValue, {
      method: "POST", body: formData,
    })
  }
}
