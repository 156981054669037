import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["animation"]
  static values = {
    "text": String,
  }

  connect() {
    this.addLoadingAnimation()

    this.form.addEventListener("turbo:submit-start", () => {
      this.animationTarget.classList.remove("hidden")
    }, { once: true })

    this.form.addEventListener("turbo:submit-end", () => {
      this.animationTarget.classList.add("hidden")
    }, { once: true })
  }

  addLoadingAnimation() {
    this.form.classList.add("position-relative")
    this.element.insertAdjacentHTML("beforeend", this.loadingAnimation)
  }

  get form() {
    return this.element.closest("form")
  }

  get loadingAnimation() {
    return `<div class="submit-animation hidden" data-${this.identifier}-target="animation">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">${this.textValue}</span>
      </div>
      ${this.textValue}
    </div>`
  }
}
