import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { basketItems: Array, userPresent: Boolean };
  productsPayload = undefined;

  connect() {
      this.prepareProductsPayload();
      if (this.userPresentValue) this.handleLogin();
  }

  prepareProductsPayload() {
    if (!this.hasBasketItemsValue) return;
    this.productsPayload = this.basketItemsValue;
  }

  pushDataLayer(actionField) {
    if (typeof window.dataLayer === 'undefined' || !this.productsPayload) return;

    window.dataLayer.push({ ecommerce: null });
    const data = {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField,
          products: this.productsPayload,
        },
      },
    }
    window.dataLayer.push(data);
  }

  handleLogin() {
    const actionField = { step: 1, option: "Account" };
    this.pushDataLayer(actionField);
  }

  handlePaymentSelection(event) {
    const option = event.target.dataset.paymentOption;
    const actionField = { step: 3, option };
    this.pushDataLayer(actionField);
  }
}
