import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"
import * as countries from "i18n-iso-countries"
import mapValues from "lodash/mapValues"
import mapKeys from "lodash/mapKeys"

export default class extends Controller {
  static values = { initialCountry: String }

  get input() {
    return this.element.querySelector("input")
  }

  get form() {
    return this.input?.closest("form")
  }

  get countryTranslations() {
    return mapKeys(mapValues(countries.getAlpha2Codes(), (_, code) => {
      return countries.getName(code, document.documentElement.lang)
    }), (_, k) => k.toLowerCase())
  }

  connect() {
    // Don't change this. This moves the utils into a separate (webpack) chunk and facilitates lazy loading
    // only on pages that need it
    // @ts-ignore
    this.utils = import(/* webpackChunkName: "intl-tel-input-utils" */ "intl-tel-input/build/js/utils")

    if (this.element instanceof HTMLInputElement) {
      console.error("Don't apply shop--tel-input directly to an input element!")
      return
    }

    const input = this.input
    if (input) {
      this.telInput = intlTelInput(input, {
        initialCountry: this.initialCountryValue,
        preferredCountries: [this.initialCountryValue],
        onlyCountries: ["AD", "AL", "AT", "BA", "BE", "BG", "BY", "CH", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IS", "IT", "LI", "LT", "LU", "LV", "MC", "MD", "MK", "MT", "NL", "NO", "PL", "PT", "RO", "RS", "RU", "SE", "SI", "SK", "SM", "TR", "UA", "US", "YU"],
        separateDialCode: true,
        localizedCountries: this.countryTranslations,
      })

      this.form.addEventListener("formdata", this.onFormData)
    }
  }

  onFormData = (evt) => {
    if (window.intlTelInputUtils) {
      // Don't try to get formatted number if intlTelInputUtils is not loaded (yet), as it will give an empty string.
      // Mainly happens on slow CI, though...
      evt.formData.set(this.input.name, this.telInput.getNumber())
    }
  }

  disconnect() {
    if (this.telInput)
      this.telInput.destroy()

    this.form?.removeEventListener("formdata", this.onFormData)
  }
}
