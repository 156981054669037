import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'link']

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.element.classList.add('clipboard--supported')
    }
  }

  copy(event) {
    event.preventDefault()
    event.stopPropagation()

    this.sourceTarget.type = 'text'
    this.sourceTarget.select()
    document.execCommand('copy')
    this.sourceTarget.type = 'hidden'

    this.element.classList.add('clipboard--copied')
    setTimeout(() => {
      this.element.classList.remove('clipboard--copied')
    }, 2000)
  }
}
