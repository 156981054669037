import { Controller } from "@hotwired/stimulus"

const ICON_UPDATED_CLASS = "basket-icon--updated";
const ANIMATION_DURATION = 1000;

export default class extends Controller {
  static values = {
    itemCount: Number
  }

  static targets = ['wrapper']

  initialize() {
    this.firstUpdate = true;
  }

  itemCountValueChanged() {
    this.wrapperTarget.dataset.value = this.decoratedValue;

    if (this.firstUpdate) {
      this.firstUpdate = false;
    } else {
      this.animate();
    }
  }

  animate() {
    this.element.classList.add(ICON_UPDATED_CLASS);

    window.setTimeout(() => this.resetAnimation(), ANIMATION_DURATION);
  }

  resetAnimation() {
    this.element.classList.remove(ICON_UPDATED_CLASS);
  }

  get decoratedValue() {
    if (this.itemCountValue === 0) return "";
    if (this.itemCountValue > 99) return "∞";

    return this.itemCountValue
  }
}
