import { Controller } from "@hotwired/stimulus"
import every from "lodash/every"
import some from "lodash/some"
import partition from "lodash/partition"


export default class TableCheckAllController extends Controller {
  get table() {
    return this.element
  }

  get headCheckBox() {
    return this.element.querySelector("thead input[type=\"checkbox\"]")
  }

  get bodyCheckBoxes() {
    return this.element.querySelectorAll("tbody td.check input[type=\"checkbox\"]")
  }

  connect() {
    this.headCheckBox.addEventListener("change", this.onCheckAllChanged)

    for (const checkbox of this.bodyCheckBoxes) {
      checkbox.addEventListener("change", this.onBodyCheckboxChanged)
      if (checkbox.checked) {
        this.checkboxChanged(checkbox)
      }
    }

    this.applyHeadCheckboxState()
  }

  disconnect() {
    this.headCheckBox.removeEventListener("change", this.onCheckAllChanged)
  }

  onBodyCheckboxChanged = (evt) => {
    this.checkboxChanged(evt.target)
    this.applyHeadCheckboxState()
  }

  applyHeadCheckboxState() {
    if (every(this.bodyCheckBoxes, (b) => b.checked)) {
      this.headCheckBox.checked = true
      this.headCheckBox.indeterminate = false
    } else if (some(this.bodyCheckBoxes, (b) => b.checked)) {
      this.headCheckBox.checked = false
      this.headCheckBox.indeterminate = true
    } else {
      this.headCheckBox.checked = false
      this.headCheckBox.indeterminate = false
    }
  }

  checkboxChanged(checkbox) {
    const row = checkbox.closest("tr")
    row.classList.toggle("tr--checked", checkbox.checked)
    const [selectedBoxes, unselectedBoxes] = partition(this.bodyCheckBoxes, (b) => b.checked)
    const selected = selectedBoxes.map((b) => b.value)
    const unselected = unselectedBoxes.map((b) => b.value)
    this.table.dispatchEvent(new CustomEvent("table:selection-change", {
      detail: { selected, unselected },
      bubbles: true,
    }))
  }

  setSelection(selected) {
    this.bodyCheckBoxes.forEach((checkbox) => {
      checkbox.checked = selected.has(checkbox.value)
    })
    this.applyHeadCheckboxState()
  }

  onCheckAllChanged = () => {
    const checked = this.headCheckBox.checked

    for (const bodyCheckBox of this.bodyCheckBoxes) {
      bodyCheckBox.checked = checked
      this.checkboxChanged(bodyCheckBox)
    }
  }
}
