import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"
import I18n from "i18n-js";

const LABEL_ID = "file-upload-label"

let progressInterval
export default class extends Controller {
  static targets = ["dropArea", "fileDroppedLabel", "fileInput", "fileList", "form", "errorMessage", "progress", "defaultLabel", "uploadingLabel"]
  static classes = ["dragover", "error", "uploading", "hidden"]
  static values = {
    formAction: String,
    allowedMimeTypes: { type: Array, default: ['image/jpeg', 'image/png', 'application/pdf', 'image/tiff'] },
    automaticSubmission: { type: Boolean, default: true },
    required: { type: Boolean, default: true },
    maxAllowedFileSize: { type: Number, default: 20971520 } // 20MB in Bytes
  }

  connect() {
    if (this.hasDropAreaTarget) this.setupDropArea()
    if (this.hasFileInputTarget) {
      this.fileInputTarget.addEventListener('change', this.handleInputChange.bind(this))
      if (this.requiredValue) {
        this.fileInputTarget.setCustomValidity(I18n.t("errors.messages.invalid"))
      }
    }

    document.documentElement.addEventListener('turbo:before-stream-render', (event) => {
      const targetId = event.target.getAttribute("target")
      if (targetId === LABEL_ID) {
        event.preventDefault()
        this.updateProgress(15).then(() => {
          this.replaceStreamTarget(event)
        })
      }
    })
  }

  replaceStreamTarget(streamEvent) {
    const targetId = streamEvent.target.getAttribute("target")
    const target = document.getElementById(targetId)

    if (targetId !== LABEL_ID || !(streamEvent.target.firstElementChild instanceof HTMLTemplateElement)) {
      return
    }

    const content = streamEvent.target.firstElementChild.content
    target.replaceWith(content)

    if (this.hasDropAreaTarget && this.hasFileInputTarget) {
      this.fileInputTarget.value = null
      this.setupDropArea()
    }
  }

  updateProgress(stepSize) {
    if (progressInterval) clearInterval(progressInterval)

    return new Promise((resolve, _reject) => {
      if (!this.hasProgressTarget) resolve()

      progressInterval = setInterval(() => {
        const currentSize = this.progressTarget.style.width.split('%')[0]
        const nextSize = parseInt(currentSize) + stepSize
        this.progressTarget.style.width = `${nextSize}%`
        if (nextSize > 100) {
          clearInterval(progressInterval)
          resolve()
        }
      }, 100)
    })
  }

  setupDropArea() {
    this.qualifyAsDropTarget(this.dropAreaTarget)
    this.dropAreaTarget.addEventListener('dragenter', this.handleDragEnter.bind(this))
    this.dropAreaTarget.addEventListener('dragleave', this.handleDragLeave.bind(this))
    this.dropAreaTarget.addEventListener('drop', this.handleDrop.bind(this))
  }

  qualifyAsDropTarget(element) {
    // These are the events that have to prevent the default event handling for the
    // element to qualify as a valid drop darget.
    // See https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
    const requiredEvents = ['dragenter', 'dragover']

    const additionalEvents = ['dragenter', 'dragleave', 'drop']

    requiredEvents.concat(additionalEvents).forEach(event => {
      element.addEventListener(event, (e) => e.preventDefault())
    })
  }

  handleDragEnter(_event) {
    this.hideFileFormatError()
    this.dropAreaTarget.classList.add(this.dragoverClass)
  }

  handleDragLeave(_event) {
    this.dropAreaTarget.classList.remove(this.dragoverClass)
  }

  handleDrop(event) {
    const files = event.dataTransfer.files

    if (this.allFilesValid(files)) {
      this.attachFiles(files)
      if(this.automaticSubmissionValue) {
        this.startUpload()
      } else {
        this.showAttachedFiles(files)
      }
    } else {
      this.showFileFormatError()
    }
  }

  handleInputChange(_event) {
    this.hideFileFormatError()
    const files = this.fileInputTarget.files

    if (this.allFilesValid(files)) {
      if(this.automaticSubmissionValue) {
        this.startUpload()
      } else {
        this.showAttachedFiles(files)
      }
    } else {
      this.showFileFormatError()
    }
  }

  startUpload() {
    this.dropAreaTarget.classList.add(this.uploadingClass)
    this.defaultLabelTarget.classList.add(this.hiddenClass)
    this.uploadingLabelTarget.classList.remove(this.hiddenClass)
    this.updateProgress(1)

    this.submit()
  }

  submit() {
    if (this.formActionValue) {
      this.form.action = this.formActionValue
    }

    Rails.fire(this.form, 'submit')
  }

  get form() {
    return this.hasFormTarget ? this.formTarget : this.element.closest('form')
  }

  showFileFormatError() {
    this.dropAreaTarget.classList.add(this.errorClass)
    this.errorMessageTarget.classList.remove(this.hiddenClass)
  }

  hideFileFormatError() {
    this.dropAreaTarget.classList.remove(this.errorClass)
    this.errorMessageTarget.classList.add(this.hiddenClass)
  }

  allFilesValid(files) {
    this.fileInputTarget.setCustomValidity('')
    const mimeTypes = this.allowedMimeTypesValue
    const maxFileSize = this.maxAllowedFileSizeValue

    return Array.from(files).every(file => mimeTypes.includes(file.type) && file.size <= maxFileSize)
  }

  attachFiles(files) {
    this.fileInputTarget.files = files
  }

  showAttachedFiles(files) {
    this.fileListTarget.innerHTML = ''

    Array.from(this.fileInputTarget.files).forEach(file => {
      const fileNameRow = document.createElement('div')
      fileNameRow.className = 'file-upload__highlight-text mb-0'
      fileNameRow.innerHTML = file.name
      this.fileListTarget.append(fileNameRow)
    })

    this.defaultLabelTarget.classList.add(this.hiddenClass)
    this.fileDroppedLabelTarget.classList.remove(this.hiddenClass)
  }
}
