import { Controller } from "@hotwired/stimulus"
import last from "lodash/last"
import { railsFetch, turboStreamFetch } from "src/utils/http"

const SEEN_CLASS = "user-notification--seen"

export default class extends Controller {
  static values = {
    markAsSeenUrl: String,
    loadMoreUrl: String,
  }

  connect() {
    this._mutationObserver = new MutationObserver(this._mutationObserved)
    this._mutationObserver.observe(this.element, { subtree: true, childList: true })

    this._unseenNotifications.forEach((item) => {
      this._observeItem(item)
      this._dispatchShowBadge(true)
    })

    this._intersectionObserver = new IntersectionObserver(this._intersectionObserved, { threshold: 0.1 })
    this._setupPagination()
  }

  _setupPagination() {
    this._notifications.forEach((item) => this._intersectionObserver.unobserve(item))
    const lastNotification = last(this._notifications)
    if (lastNotification) {
      this.element.querySelector(".user-notifications__empty")?.remove()
      this._intersectionObserver.observe(lastNotification)
    }
  }

  get _notifications() {
    return this.element.querySelectorAll(`.user-notification`)
  }

  get _unseenNotifications() {
    return this.element.querySelectorAll(`.user-notification:not(.${SEEN_CLASS})`)
  }

  _observeItem(item) {
    if (item.classList.contains(SEEN_CLASS)) return
    item.addEventListener("mouseover", this._itemMouseOver)
  }

  _unobserve(item) {
    item.removeEventListener("mouseover", this._itemMouseOver)
  }

  _itemMouseOver = (evt) => {
    this._itemHasBeenSeen(evt.target)
  }

  disconnect() {
    this._mutationObserver.disconnect()
    this._intersectionObserver.disconnect()
  }

  _mutationObserved = (mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type !== "childList") return

      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLElement && node.classList.contains("user-notification")) {
          this._newItem(node)
        }
      })
    })
  }

  _newItem(node) {
    this._dispatchShowBadge(true)
    this._observeItem(node)
    this._setupPagination()
    this._toggleBadge()
  }

  _dispatchShowBadge(show) {
    this.element.dispatchEvent(new CustomEvent("shop:nav-item-badge", { detail: { show }, bubbles: true }))
  }

  async _itemHasBeenSeen(item) {
    this._unobserve(item)

    const id = item.dataset.id
    await railsFetch(
      this.markAsSeenUrlValue.replace(":id", id),
      {
        method: "PUT",
      },
    )

    item.classList.add(SEEN_CLASS)

    this._toggleBadge()
  }

  _toggleBadge() {
    this._dispatchShowBadge(this._unseenNotifications.length > 0)
  }

  _intersectionObserved = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target instanceof HTMLElement) {
        this._intersectionObserver.unobserve(entry.target)
        this._loadMore(entry.target)
      }
    })
  }

  async _loadMore(item) {
    const url = new URL(this.loadMoreUrlValue)
    url.searchParams.set("before", item.dataset.timestamp)
    await turboStreamFetch(url.toString())
  }
}
