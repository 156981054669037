import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create("ReadyChannel", {
  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  },

  rejected() {
    console.error('Could not establish cable')
  }
});
