import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit(event) {
    event.preventDefault()

    this.element.querySelectorAll('[required]').forEach((input) => {
      input.required = false;
    });

    if (this.isButton(event.target)) {
      this.element.requestSubmit(event.target);
    } else {
      this.element.requestSubmit();
    }
  }

  isButton(element) {
    return element.tagName === 'BUTTON' && element.type === 'submit' ||
      element.tagName === 'INPUT' && element.type === 'submit';
  }
}
