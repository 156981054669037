/* eslint quote-props: ["error", "consistent"] */
/* global Payone */
import { Controller } from '@hotwired/stimulus'

const inputStyle = {
  'border-radius': '5px',
  'background': '#fff',
  'border': '1px solid #dadada',
  'color': '#33393e',
  'font-size': '14px',
  'height': '33px',
  'line-height': '1.5',
  'margin': '3px',
  'outline': 'none',
  'padding': '5px 10px',
  'transition': 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  'width': 'calc(100% - 6px)',
}

const inputFocusStyle = {
  ...inputStyle,
  'border': '1px solid #48caff',
  'box-shadow': '0 0 0 3px rgba(0, 141, 199, 0.25)',
}

/**
 * Allow-list for styles to be copied from <input> prototype element.
 */
const allowedInputProtoStyleKeys = [
  'box-sizing',
  'border-radius',
  'border',
  'color',
  'font-size',
  'height',
  'line-height',
  'margin',
  'outline',
  'padding',
  'transition',
  'width'
]

function convertStyleHash(obj) {
  return Object.entries(obj).map((tuple) => tuple.join(': ')).join(';')
}

export default class extends Controller {
  static targets = [
    'form',
    'cardType',
    'inputStyleProto', // <input> tag to copy styles from
    'pseudoAccountNumber',
    'accountNumber',
    'cardType',
    'cardExpiresAt'
  ]

  static values = {
    signedRequest: Object,
    supportedCardtypes: Array,
    placeholders: Object,
  }

  connect() {
    this.injectScript().then(() => {
      Payone.ClientApi.Language[this.currentLanguage].placeholders.expireMonth = 'MM'
      Payone.ClientApi.Language[this.currentLanguage].placeholders.expireYear = 'YYYY'

      if (this.hasPlaceholdersValue) {
        Object.keys(this.placeholdersValue).forEach((k) => {
          Payone.ClientApi.Language[this.currentLanguage].placeholders[k] = this.placeholdersValue[k]
        })
      }

      window.payoneCallback = (response) => {
        if (response.status === 'VALID') {
          this.pseudoAccountNumberTarget.value = response.pseudocardpan
          this.accountNumberTarget.value = response.truncatedcardpan
          this.cardTypeTarget.value = response.cardtype
          this.cardExpiresAtTarget.value = response.cardexpiredate
          this.formTarget.submit()
        }
      }
      this.iframe = new Payone.ClientApi.HostedIFrames(this.payoneConfiguration(), this.signedRequestValue)
    }).catch((_error) => {
      console.error(_error);
    })
  }

  submit(ev) {
    this.check(ev)
  }

  check(ev) {
    ev.preventDefault()
    if (this.iframe.isComplete()) {
      this.iframe.creditCardCheck('payoneCallback')
    } else {
      // TODO: handle case when form is not completed
    }
  }

  injectScript() {
    return new Promise((resolve, reject) => {
      if (!window.Payone) {
        const script = document.createElement('script')
        script.setAttribute('id', 'payoneScript')
        script.async = true
        script.defer = true
        script.src = 'https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js' // payone_hosted_min.js

        script.addEventListener('load', resolve)
        script.addEventListener('error', () => reject(new Error('Error loading script.')))
        script.addEventListener('abort', () => reject(new Error('Script loading aborted.')))
        document.head.appendChild(script)
      } else {
        resolve()
      }
    })
  }

  payoneConfiguration() {
    // https://docs.payone.com/display/public/PLATFORM/Hosted-iFrame+Mode+-+Table+of+config+attributes
    // https://docs.payone.com/display/public/PLATFORM/Hosted-iFrame+Mode+-+Table+of+config+defaultStyle
    return {
      fields: {
        cardpan: {
          selector: 'cardpan',
          type: 'text',
        },
        cardcvc2: {
          selector: 'cardcvc2',
          type: 'password',
          size: '4',
          maxlength: '4',
          length: {'A': 4, 'V': 3, 'M': 3, 'J': 0},
        },
        cardexpiremonth: {
          selector: 'cardexpiremonth',
          type: 'text',
          maxlength: '2',
          iframe: this.hasInputStyleProtoTarget ? {} : {
            width: '40%',
          },
        },
        cardexpireyear: {
          selector: 'cardexpireyear',
          type: 'text',
          maxlength: '4',
          iframe: this.hasInputStyleProtoTarget ? {} : {
            width: '40%',
          },
        },
      },
      defaultStyle: this.getDefaultStyle(),
      autoCardtypeDetection: {
        supportedCardtypes: this.supportedCardtypesValue,
        callback: (detectedCardtype) => {
          this.setCardType(detectedCardtype)
        },
      },
      error: 'errorOutput',
      language: Payone.ClientApi.Language[this.currentLanguage],
    }
  }

  getDefaultStyle() {
    if (this.hasInputStyleProtoTarget) {
      return this.getDefaultStyleFromInputPrototype()
    }

    return {
      input: convertStyleHash(inputStyle),
      inputFocus: convertStyleHash(inputFocusStyle),
      iframe: {
        height: '39px',
        width: '100%',
      },
    }
  }

  /**
   * Copies the (allow-listed) styles from the input prototype element.
   *
   * Remember: the payone input fields are in iframes that whose style we can not influence
   * directly. We can, though, give a inline style string to the Payone client.
   */
  getDefaultStyleFromInputPrototype() {
    const style = {}
    let computedStyle = window.getComputedStyle(this.inputStyleProtoTarget)
    Object.values(computedStyle).forEach((computedCssProperty) => {
      if (allowedInputProtoStyleKeys.some(x => computedCssProperty.startsWith(x))) {
        style[computedCssProperty] = computedStyle[computedCssProperty]
      }
    })

    if (computedStyle.width === '100%') {
      // HACK: There is a rounding issue here. The iframe gets proper non-integer px-width,
      // but the input element's px-width gets rounded, which causes clipping.
      style.width = 'calc(100% - 1px)'
    }

    return {
      input: convertStyleHash(style),
      inputFocus: convertStyleHash(style),
      iframe: {
        height: computedStyle.height,
        width: '100%',
      },
    }
  }

  setCardType(cardType) {
    this.cardTypeTargets.forEach((card) => {
      card.classList.toggle('active', (card.dataset.cardType === cardType || cardType === '?'))
    })
  }

  get currentLanguage() {
    return document.documentElement.lang.substring(0, 2)
  }
}
