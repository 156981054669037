import { Controller } from "@hotwired/stimulus"
import TableCheckAllController, { SelectionChangeDetails } from "./table_check_all_controller"
import BatchActionPanelController from "../components/batch_action_panel_controller"


export default class extends Controller {
  static targets = [
    'table',
    'panel',
    'form'
  ]
  static values = {
    paramName: String
  }

  _selected = new Set()

  get tableCheckAllController() {
    return this.application.getControllerForElementAndIdentifier(this.tableTarget, "shop--table-check-all")
  }

  get panelController() {
    return this.application.getControllerForElementAndIdentifier(this.panelTarget, "shop--batch-action-panel")
  }

  connect() {
    this.element.addEventListener("table:selection-change", this.selectionChanged)
  }

  clear() {
    this._selected.forEach((value) => {
      this._removeHiddenInputFor(value)
    })
    this._selected.clear()
    this._restoreSelection()
    this.panelController.count = this._selected.size
  }

  tableTargetConnected(table) {
    this._restoreSelection()
  }

  selectionChanged = (evt) => {
    this._adjustSelection(evt.detail)
    this.panelController.count = this._selected.size
  }

   _adjustSelection(detail) {
    detail.unselected.forEach((value) => {
      this._selected.delete(value)
      this._removeHiddenInputFor(value)
    })
    detail.selected.forEach((value) => {
      this._selected.add(value)
      this._addHiddenInputFor(value)
    })
  }

   _removeHiddenInputFor(value) {
    const hiddenInput = this._getHiddenInput(value)
    hiddenInput?.remove()
  }

   _addHiddenInputFor(value) {
    const hiddenInput = this._getHiddenInput(value)
    if (!hiddenInput) {
      this.formTarget.insertAdjacentElement("afterbegin", this.createHiddenInput(value))
    }
  }

   _getHiddenInput(value) {
    return this.formTarget.querySelector(`input[type="hidden"][name="${this.paramNameValue}"][value="${value}"]`)
  }

   _restoreSelection() {
    this.tableCheckAllController?.setSelection(this._selected)
  }

   createHiddenInput(value) {
    const input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", this.paramNameValue)
    input.setAttribute("value", value)
    return input
  }
}
