import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { railsFetch } from "src/utils/http"
import { createStreamReplaceMessage } from "src/utils/turbo"

function recursivelyFindSubBloks(childNodes) {
  let bloks = []
  let current

  for (const node of childNodes) {
    if (node.nodeType === Node.COMMENT_NODE && node.textContent.match(/#storyblok#/)) {
      const uid = tryParseUid(node.textContent)
      current = { uid, blok: null, children: [] }
      bloks.push(current)
    } else if (node.nodeType === Node.ELEMENT_NODE && current) {
      current.blok = node.outerHTML
      current.children.push(...recursivelyFindSubBloks(node.childNodes))

      // Found the blok correspondig to the comment
      current = undefined
    }
  }

  return bloks
}

function dissectRenderedBloks(html) {
  const fragment = document.createElement("template")
  fragment.innerHTML = html

  return recursivelyFindSubBloks(fragment.content.childNodes)
}

function tryParseUid(comment) {
  return JSON.parse(comment.replace("#storyblok#", "")).uid
}

function blokUidSelector(uid) {
  return `[data-blok-c*="${uid}"]`
}

function renderTurboBloks(bloks) {
  bloks.forEach(({ uid, blok, children }) => {
    const uidSelector = blokUidSelector(uid)
    if (blok && document.querySelector(uidSelector)) {
      // If there is an element we can replace
      const message = createStreamReplaceMessage(uidSelector, blok)
      Turbo.renderStreamMessage(message)
    } else {
      // Otherwise try sub-bloks
      renderTurboBloks(children)
    }
  })
}

class StoryBlokHelper {
  constructor(updateUrl) {
    this.updateUrl = updateUrl
    this.cache = {}
  }

  addScrollRestorationListener() {
    document.addEventListener("turbo:render", () => {
      this.scrollTarget = document.querySelector("[data-scroll-target]") || document.documentElement
      if (this.scrollPosition) {
        this.scrollTarget.scrollLeft = this.scrollPosition[0]
        this.scrollTarget.scrollTop = this.scrollPosition[1]
      }

      setTimeout(() => {
        document.body.classList.remove("no-effects")
      }, 500)

      this.storyblokInstance.enterEditmode()
    }, false)
  }

  install() {
    this.injectBridgeScript()
    this.addScrollRestorationListener()

    return this
  }

  injectBridgeScript() {
    const bridgeScript = document.createElement("script")
    bridgeScript.src = "https://app.storyblok.com/f/storyblok-v2-latest.js"
    bridgeScript.onload = () => this.onBridgeLoaded()
    document.head.append(bridgeScript)
  }

  onBridgeLoaded() {
    this.storyblokInstance = new StoryblokBridge()

    this.storyblokInstance.on(["change", "published"], () => {
      this.reload()
    })

    let debouncedRender = debounce((story) => {
      this.renderViaTurbo(story)
    }, 1000, { trailing: true })

    this.storyblokInstance.on(["input"], (e) => {
      debouncedRender(e.story)
    })
  }

  /**
   * Sends the locally changed story to the backend for rendering and inserts the result via Turbolinks
   */
  async renderViaTurbo(story) {
    this.scrollPosition = [this.scrollTarget.scrollLeft, this.scrollTarget.scrollTop]

    const currentUrl = new URL(window.location)
    const url = this.updateUrl + "?" + currentUrl.searchParams.toString()

    const body = JSON.stringify({ story: story })

    document.body.classList.add("no-effects")

    const response = await railsFetch(url, {
      method: "POST",
      body: body,
    })

    if (!response.ok) {
      console.error(response)
    }

    const renderedStory = await response.text()

    const fragment = document.createElement("template")
    fragment.innerHTML = renderedStory

    let uidSelector = blokUidSelector(story.content._uid)

    if (document.querySelector(uidSelector)) {
      const message = createStreamReplaceMessage(uidSelector, renderedStory)
      Turbo.renderStreamMessage(message)
    } else {
      const bloks = dissectRenderedBloks(renderedStory)
      renderTurboBloks(bloks)
    }

    setTimeout(() => {
      this.storyblokInstance.enterEditmode()
    }, 10)
  }

  reload() {
    this.scrollPosition = [this.scrollTarget.scrollLeft, this.scrollTarget.scrollTop]
    Turbo.visit(window.location)
  }
}

export default class extends Controller {
  static values = {
    updateUrl: String,
  }

  connect() {
    if (!window.location.href.match(/_storyblok/)) return
    if (!window.storyblokHelper) {
      window.storyblokHelper = new StoryBlokHelper(this.updateUrlValue).install()
    }

    window.storyblokHelper.scrollTarget = document.querySelector("[data-scroll-target]") || document.documentElement
  }
}
