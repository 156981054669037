import { Controller } from '@hotwired/stimulus'

const BUTTON_ACTIVE_CLASS = "summary-toggle-button--active"

export default class extends Controller {
  static targets = ['summarySheet', 'toggleButton']

  connect() {
    this.body = document.getElementsByTagName('body')[0]
  }

  disconnect() {
    // Make sure we don't break the body by accident
    this.body.classList.remove('noscroll-y')
  }

  isOverlayOpen() {
    return this.summarySheetTarget.style.display === "block"
  }

  openOverlay() {
    this.summarySheetTarget.style.display = "block"
    this.toggleButtonTarget.classList.add(BUTTON_ACTIVE_CLASS)
    this.body.classList.add('noscroll-y')
  }

  closeOverlay() {
    this.summarySheetTarget.style.display = "none"
    this.toggleButtonTarget.classList.remove(BUTTON_ACTIVE_CLASS)
    this.body.classList.remove('noscroll-y')
  }

  toggle() {
    this.isOverlayOpen() ? this.closeOverlay() : this.openOverlay()
  }
}
