import {Controller} from "@hotwired/stimulus"
import I18n from "i18n-js";
import "@selectize/selectize"

export default class extends Controller {
  static targets = [
    'select',
    'error',
  ]

  static values = {
    url: String
  }

  connect() {
    this.selectTarget.addEventListener('change', this.checkNumber)
  }

  checkNumber = () => {
    this.selectedNumber = this.getNumber()
    if (this.selectedNumber == "") {
      return
    }

    const url = new URL(this.urlValue)
    url.searchParams.append("number", this.selectedNumber)

    fetch(url.toString())
      .then((response) => response.json())
      .then((data) => this.handleData(data));
  }

  handleData(data) {
    this.toggleInfo(data)
    if (data == true) {
      this.updateSelectize()
    }
  }

  toggleInfo(data) {
    this.errorTarget.classList.toggle('d-none', !data)
    this.errorTarget.classList.toggle('d-block', data)
    if (data == true) {
      this.errorTarget.innerText = I18n.t('javascript.unassigned_numbers.already_assigned', { number: this.selectedNumber })
    }
  }

  updateSelectize() {
    const selectize = this.selectTarget.selectize
    selectize.removeOption(selectize.getValue())
  }

  getNumber() {
    return this.selectTarget.value
  }
}
