import { Controller } from '@hotwired/stimulus'

const HIDDEN_CLASS = 'flash--hidden'
const DISPLAY_TIME = 5000

export default class extends Controller {

  connect() {

    // After the opacity transition has run, actually reomve element
    // from DOM again
    this.element.addEventListener('transitionend', () => {
      this.element.remove()
    })

    this.element.classList.remove(HIDDEN_CLASS)

    setTimeout(() => {
      this.element.style.opacity = '0.0'
    }, DISPLAY_TIME)
  }
}
