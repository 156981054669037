import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']
  static values = {
    itemSelected: String
  }

  connect() {
    this.registerAnchorHandlers()
  }

  registerAnchorHandlers() {
    this.itemTargets.forEach((itm, i) => {
      let id = itm.getAttribute('id')
      if (!id) return

      if ('#' + id === window.location.hash) {
        this.openAndScrollTo(i, itm)
      }

      document.querySelectorAll(`a[href="#${id}"]`).forEach((link) => {
        link.addEventListener('click', (evt) => {
          evt.preventDefault()
          this.openAndScrollTo(i, itm)
          return false
        })
      })
    })
  }

  openAndScrollTo(i, itm) {
    this.openIndex(i, true)
    itm.scrollIntoView()
  }

  openItem(evt) {
    this.openIndex(this.itemTargets.indexOf(evt.currentTarget.parentElement))
  }

  openIndex(n, dontToggle) {
    this.itemTargets.forEach((itm, i) => {
      if (i === n) {
        if (dontToggle && itm.classList.contains(this.itemSelectedValue)) {
          window.location.hash = ''
          return
        }

        itm.classList.toggle(this.itemSelectedValue)
        const url = new URL(window.location)
        url.hash = itm.getAttribute('id') || ''
        window.history.replaceState({}, '', url)
      } else {
        itm.classList.remove(this.itemSelectedValue)
      }
    })
  }
}
