import { Controller  } from "@hotwired/stimulus"
import { currencyFormatter } from "src/utils/money"

export default class extends Controller {
  static targets = ['monetizedLabel', 'input'];
  static values = {
    currency: String,
  }

  connect() {
    this.inputTargets.forEach(target => target.dispatchEvent(new Event('change')));
  }

  calculateMonetizedValues = (event) => {
    const monetizedValues = JSON.parse(event.target.dataset.formMathMonetizedValues)

    Object.keys(monetizedValues).forEach(key => {
      const result = monetizedValues[key] * event.target.value
      const targetLabel = this.monetizedLabelTargets.find(label => label.dataset.formMathMonetizedType === key)

      targetLabel.innerHTML = currencyFormatter(this.currencyValue).format(result)
    })
  }
}
