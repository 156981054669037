import { Controller } from "@hotwired/stimulus"

const DISABLED_BTN_CLASS = 'btn-text--disabled'


export default class extends Controller {
  static targets = ['input', 'buttonStepUp', 'buttonStepDown']

  connect() {
    if (this.element.classList.contains('number-input')) {
      return
    }

    this.element.classList.add('number-input')

    this.inputTarget.insertAdjacentHTML('beforebegin', this.btnStepDown())
    this.inputTarget.insertAdjacentHTML('afterend', this.btnStepUp())
    this.updateButtons()

    this.copyValueToWrapper()

    this.inputTarget.addEventListener('change', (event) => {
      this.copyValueToWrapper()
    })
  }

  disconnect() {
    this.element.classList.remove('number-input')
    this.element.querySelectorAll('.btn').forEach(button => button.remove());
  }

  get value() {
    return this.inputTarget.value
  }

  copyValueToWrapper() {
    this.element.dataset.value = this.value
  }

  stepUp(event) {
    let element = event.target.previousElementSibling
    if (event.target.classList.contains(DISABLED_BTN_CLASS)) { return }

    element.stepUp()
    element.dispatchEvent(new Event('change', { bubbles: true }))
    this.updateButtons()
  }

  stepDown(event) {
    let element = event.target.nextElementSibling
    if (event.target.classList.contains(DISABLED_BTN_CLASS)) { return }

    element.stepDown()
    element.dispatchEvent(new Event('change', { bubbles: true }))
    this.updateButtons()
  }


  updateButtons = () => {
    this.buttonStepDownTarget.classList.remove(DISABLED_BTN_CLASS)
    this.removeTooltip(this.buttonStepDownTarget)

    this.buttonStepUpTarget.classList.remove(DISABLED_BTN_CLASS)
    this.removeTooltip(this.buttonStepUpTarget)

    if (this.inputTarget.disabled || this.inputTarget.readOnly) {
      this.buttonStepDownTarget.classList.add(DISABLED_BTN_CLASS)
      this.setTooltip(this.inputTarget.dataset.disabledTooltip, this.buttonStepDownTarget)

      this.buttonStepUpTarget.classList.add(DISABLED_BTN_CLASS)
      this.setTooltip(this.inputTarget.dataset.disabledTooltip, this.buttonStepUpTarget)
    } else {
      if (this.inputValue() <= this.minValue()) {
        this.buttonStepDownTarget.classList.add(DISABLED_BTN_CLASS)
        this.setTooltip(this.inputTarget.dataset.minDisabledTooltip, this.buttonStepDownTarget)
      }
      if (this.inputValue() >= this.maxValue()) {
        this.buttonStepUpTarget.classList.add(DISABLED_BTN_CLASS)
        this.setTooltip(this.inputTarget.dataset.maxDisabledTooltip, this.buttonStepUpTarget)
      }

      if (this.minValue() && this.minValue() === this.maxValue()) {
        this.inputTarget.setAttribute('disabled', '')
      }
    }

  }

  maxValue() {
    return parseInt(this.inputTarget.max)
  }

  minValue() {
    return parseInt(this.inputTarget.min)
  }

  inputValue() {
    return parseInt(this.inputTarget.value)
  }

  removeTooltip(btn) {
    btn.removeAttribute("title")
    delete btn.dataset.bsToggle
  }

  setTooltip(tooltip, btn) {
    if (!tooltip) return

    btn.setAttribute("title", tooltip)
    btn.dataset.bsToggle = "tooltip"
  }

  btnStepUp() {
    return `<span class="btn btn-lg btn-text" data-action="click->number-input#stepUp" data-number-input-target="buttonStepUp">+</span>`
  }

  btnStepDown() {
    return `<span class="btn btn-lg btn-text" data-action="click->number-input#stepDown" data-number-input-target="buttonStepDown">&ndash;</span>`
  }
}
