import { Controller } from "@hotwired/stimulus"

export default class BatchActionPanelController extends Controller {
  static targets = ["zero", "one", "other", "count"]

  _count = 0

  set count(value) {
    this._count = value
    this._updateView()
  }

  get count() {
    return this._count
  }

  connect() {
    this._updateView()
  }

  _updateView() {
    this.zeroTarget.classList.toggle("hidden", this._count !== 0)
    this.oneTarget.classList.toggle("hidden", this._count !== 1)
    this.otherTarget.classList.toggle("hidden", this._count <= 1)
    this.countTarget.innerText = this.count.toString()
    this.element.classList.toggle("hidden", this._count === 0)
  }
}
