import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    toggleUrl: String,
    isFavorited: Boolean
  }

  toggle(ev) {
    ev.preventDefault()

    fetch(this.toggleUrlValue, {
      method: this.isFavoritedValue ? 'DELETE' : 'PUT'
    })

    return false
  }
}
