import { Controller } from '@hotwired/stimulus'
import Carousel from 'bootstrap/js/dist/carousel'

export default class extends Controller {
  static targets = ['next', 'prev']
  static values = { perPage: Number }

  connect() {
    this.carousel = new Carousel(this.element, { interval: false })

    this.items = this.element.querySelectorAll('.carousel .carousel-item')

    this.setPerPageNumber()
    window.onresize = () => {
      this.setPerPageNumber()
    }
  }

  buildItems() {
    this.totalPages = Math.ceil(this.items.length / this.perPage)
    this.items.forEach((el) => {
      let next = el.nextElementSibling
      for (var i = 1; i < this.perPageValue; i++) {
        if (!next) return

        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
      }
    })
  }

  buildPagination = () => {
    this.element.querySelector('.pagination').innerHTML = ''

    for (let i = 0; i < this.totalPages; i++) {
      let dot = document.createElement('div')
      dot.dataset.action = 'click->shop--dot-pagination#goToPage'
      dot.dataset.page = i
      dot.classList.add('dot-pagination__dot')

      if (this.totalPages > 1) {
        this.element.querySelector('.pagination')
          .appendChild(dot)
      }
    }

    this.updatePagination(this.currentPage(this.activeElement()))
  }

  isFirstPage() {
    return this.currentPage(this.activeElement()) === 0
  }

  isLastPage() {
    return this.currentPage(this.activeElement()) === this.totalPages - 1
  }

  updatePagination(page) {
    let p = 0
    this.prevTarget.classList.toggle('d-none', this.isFirstPage())
    this.nextTarget.classList.toggle('d-none', this.isLastPage())

    this.element.querySelectorAll('.dot-pagination__dot')
      .forEach((el) => {
          if (page < 0) {
            p = 0
          } else if (page >= this.totalPages) {
            p = this.totalPages - 1
          } else {
            p = page
          }

          if (el.dataset.page == p) {
            el.classList.add('current')
          } else {
            el.classList.remove('current')
          }
        }
      )
  }

  activeElement = () => {
    this.activeBlock = document.querySelector('.active')

    return this.activeBlock.dataset.index
  }

  setPerPageNumber = () => {
    let width = document.documentElement.clientWidth

    if (width >= 992) {
      this.perPage = 4
    } else if (width >= 768) {
      this.perPage = 2
    } else {
      this.perPage = 1
    }

    this.buildItems()
    this.buildPagination()
  }

  nextBlock = () => {
    return parseInt(this.activeElement()) + this.perPage
  }

  prevBlock = () => {
    return parseInt(this.activeElement()) - this.perPage
  }

  currentPage(element) {
    return Math.floor(element / this.perPage)
  }

  goToPage = (element) => {
    let page = element.target.dataset.page
    this.carousel.to(page * this.perPage)
    this.updatePagination(page)
  }

  nextSlide = () => {
    this.carousel.to(this.nextBlock())
    this.updatePagination(this.currentPage(this.activeElement()))

  }

  prevSlide = () => {
    this.carousel.to(this.prevBlock())
    this.updatePagination(this.currentPage(this.activeElement()))

  }
}
