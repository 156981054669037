import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    defaultSubtitle: String,
    initialSubtitle: String
  }
  static targets = ['firstnameInput', 'lastnameInput', 'licenseInfoSubtitle']

  connect() {
    this.updateLicenseInfos()
  }

  updateLicenseInfos = () => {
    if (this.hasLicenseInfoSubtitleTarget) {
      if (this.firstnameInputTarget.value && this.lastnameInputTarget.value) {
        this.licenseInfoSubtitleTarget.innerHTML = this.initialSubtitleValue.replace("%{new_user_name}", `${this.firstnameInputTarget.value} ${this.lastnameInputTarget.value}`)
      } else {
        this.licenseInfoSubtitleTarget.innerHTML = this.defaultSubtitleValue
      }
    }
  }
}
