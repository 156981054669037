import { Controller } from "@hotwired/stimulus"
import merge from "lodash/merge"
import debounce from "lodash/debounce"


export default class extends Controller {
  static values = {
    config: Object,
    tooltips: Array,
    unit: String,
  }

  disconnect() {
    this.chart.destroy()
  }

  async connect() {
    const chartJsModule = import(/* webpackChunkName: "chartjs" */ "chart.js/auto")

    const Chart = (await chartJsModule).default

    const config = {
      ...this.configValue,
      data: {
        ...this.configValue.data,
        datasets: this.configValue.data.datasets.map((dataset) => ({
          labels: dataset.labels,
        })),
      },
      options: merge(
        this.configValue.options,
        {
          onResize: (chart, size) => this.onResize(chart, size),
          scales: {
            y: {
              ticks: {
                callback: (value) => this.unitValue ? `${value} ${this.unitValue}` : value,
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (item, data) => {
                  return this.tooltipsValue[item.dataIndex] || item.dataset.data[item.dataIndex]
                },
              },
            },
            ...this.configValue.options.plugins,
          },
        }),
    }

    this.chart = new Chart(this.element, config)
    this.chart.update()
    this.element.controller = this
    this.alreadyRenderedOnce = false

    setTimeout(() => {
      this.alreadyRenderedOnce = true
      this.chart.data.datasets = this.prepareDatasets()
      this.chart.update()
    }, 500)
  }

  prepareDatasets() {
    return this.configValue.data.datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: this.getColor(dataset.backgroundColor),
      borderColor: this.getColor(dataset.borderColor),
      fill: dataset.fill ? {
        ...dataset.fill,
        above: this.getColor(dataset.fill.above),
      } : undefined,
    }))
  }

  /**
   * Parses and applies CSS color variables
   * @param color
   * @returns {string|*}
   */
  getColor(color) {
    if (!color) return

    switch (typeof color) {
      case "object":
        return this.getColorObject(color)
      case "string":
        return this.getColorString(color)
      default:
        break
    }
  }

  getColorObject(color) {
    if ("gradient" in color) {
      const ctx = this.element.getContext("2d")

      let { x0, y0, x1, y1 } = color.gradient.coords

      const gradient = ctx.createLinearGradient(x0, y0, x1 * this.chart.width, y1 * this.chart.height)

      for (let { position, color: stopColor } of color.gradient.stops) {
        gradient.addColorStop(position, stopColor)
      }

      return gradient
    }
  }

  getColorString(color) {
    const variableMatch = color.match(/var\((.+)\)/)
    if (variableMatch) {
      return this.getComputedColor(variableMatch[1])
    } else {
      return color
    }
  }

  getComputedColor(name) {
    return getComputedStyle(this.element).getPropertyValue(name)
  }

  onResize = debounce((chart, size) => {
    if(!this.chart || !this.alreadyRenderedOnce) return

    chart.data.datasets = this.prepareDatasets()
    chart.update('resize')
  }, 100)
}
