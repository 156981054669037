import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

const HIDE_CLASS = "basket__loading-indicator--hidden"
export default class extends Controller {
  static targets = ['loadingIndicator']

  addToBasket = _event => {
    this.showLoadingIndicator();
  }

  changeAmount = event => {
    // this is the way of submitting a remote form via stimulusjs. otherwise it's submitted as text/html
    Rails.fire(event.target.closest('form'), 'submit')
    this.showLoadingIndicator();
  }

  showLoadingIndicator = () => {
    this.loadingIndicatorTarget.classList.remove(HIDE_CLASS);
  }
}
