import { Controller } from "@hotwired/stimulus"
import { EventEmitter } from 'events'

const EventBus = new EventEmitter()

const SLIDER_TARGETS = {
  main: 'main',
  thumb: 'thumb'
}

const EVENTS = {
  activeSlideChanged: 'activeSlideChanged',
  fullscreenTriggered: 'fullscreenTriggered'
}

const CHROME_REGEX = /((Chromium|Chrome)\/(20\.0|20\.([1-9]|\d{2,})|(2[1-9]|[3-9]\d|\d{3,})\.\d+)(?:\.\d+)?)/

const THUMB_ACTIVE_CLASS = "image-carousel-thumbnails__thumbnail--active"
const THUMB_NAV_HIDDEN_CLASS = "image-carousel-thumbnails__nav--hidden"

export default class extends Controller {
  static targets = ['mainSlide', 'thumbSlide', 'next', 'prev']
  currentSlides = {}
  activeSlideIndex = 0

  connect() {
    if (!this.mainSlideTargets.length) return
    EventBus.on(EVENTS.activeSlideChanged, (index) => this.updateSlidePositions(index))
    EventBus.on(EVENTS.fullscreenTriggered, () => this.becameVisible())

    this.setupIntersectionObserver(SLIDER_TARGETS.main)
    this.setupIntersectionObserver(SLIDER_TARGETS.thumb)

    this.thumbSlideTargets.forEach((indicator, index) => {
      indicator.addEventListener('click', () => {
        this.slideTo(index)
        this.broadcastSlideChange(index)
      })
    })

    this.nextTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.scrollRight()
      })
    })

    this.prevTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.scrollLeft()
      })
    })
  }

  browserIsChrome() {
    return CHROME_REGEX.test(navigator.userAgent)
  }

  slideTo(index) {
    // FIXME: (cpoplaws) this is just a temporary solution for scrolling issues with 'behaviour: smooth' in chrome,
    // which for some reason only affetcs the main slider targets. While the debugging is in porcess, I would like to have
    // at leas a functioning version for chrome out in production.
    const scrollBehavior = this.browserIsChrome() ? 'auto' : 'smooth'

    this.scrollTo(index, SLIDER_TARGETS.thumb, {behavior: scrollBehavior, inline: 'center'})
    this.scrollTo(index, SLIDER_TARGETS.main, {behavior: scrollBehavior})
    this.setActiveThumbnail(index)
    this.activeSlideIndex = index
  }

  broadcastSlideChange(newIndex) {
    EventBus.emit(EVENTS.activeSlideChanged, newIndex)
  }

  updateSlidePositions(newIndex) {
    if (this.activeSlideIndex === newIndex) return

    this.slideTo(newIndex)
  }

  becameVisible() {
    this.scrollTo(this.activeSlideIndex, undefined, {behavior: 'auto'})
    this.scrollTo(this.activeSlideIndex, SLIDER_TARGETS.thumb, {behavior: 'auto', inline: 'center'})
  }

  triggerFullscreen() {
    EventBus.emit(EVENTS.fullscreenTriggered)
  }

  setActiveThumbnail(index) {
    if (!this.thumbSlideTargets[index]) return

    this.thumbSlideTargets.forEach(slide => {
      slide.classList.remove(THUMB_ACTIVE_CLASS)
    })
    this.thumbSlideTargets[index].classList.add(THUMB_ACTIVE_CLASS)
  }

  scrollRight() {
    const lastVisible = this.getLastVisible()

    if (lastVisible && lastVisible + 1 < this.thumbSlideTargets.length)
      this.scrollTo(lastVisible + 1, SLIDER_TARGETS.thumb)
  }

  getLastVisible() {
    return Math.max(...this.currentSlides[SLIDER_TARGETS.thumb])
  }

  scrollLeft() {
    const firstVisible = this.getFirstVisible()

    if (firstVisible && firstVisible > 0)
      this.scrollTo(firstVisible - 1, SLIDER_TARGETS.thumb)
  }

  getFirstVisible() {
    return Math.min(...this.currentSlides[SLIDER_TARGETS.thumb])
  }

  scrollTo(index, target = SLIDER_TARGETS.main, options = {}) {
    const defaultOptions = {behavior: 'smooth', block: 'nearest', inline: 'nearest', ...options}
    const slideTargets = this.getSlideTargetsFor(target)

    slideTargets[index]?.scrollIntoView(defaultOptions)
  }

  setupIntersectionObserver(target) {
    const callback = target === SLIDER_TARGETS.main ? this.mainCallback : this.thumbCallback
    const slideTargets = this.getSlideTargetsFor(target)

    let root = this.element === document.body ? null : this.element
    this.observer = new IntersectionObserver(callback.bind(this), {
      root: root,
      threshold: 0.5
    })

    slideTargets.forEach(target => {
      this.observer.observe(target)
    })

    this.currentSlides[target] = new Set()
  }

  thumbCallback(entries, _observer) {
    return this.callback(entries, SLIDER_TARGETS.thumb)
  }

  mainCallback(entries, _observer) {
    return this.callback(entries, SLIDER_TARGETS.main)
  }

  callback(entries, target) {
    if (!entries.length) return
    const slides = this.getSlideTargetsFor(target)

    let activatedSlide
    entries.forEach((entry) => {
      let entryIndex = slides.indexOf(entry.target)
      if (entry.isIntersecting) {
        if (!activatedSlide || entry.intersectionRatio > activatedSlide.intersectionRatio) {
          activatedSlide = entry
        }

        this.currentSlides[target].add(entryIndex)
      } else {
        this.currentSlides[target].delete(entryIndex)
      }
    })

    if (target === SLIDER_TARGETS.thumb) this.adjustButtons()
    this.dispatchEvent(activatedSlide, SLIDER_TARGETS.thumb)
  }

  getSlideTargetsFor(target) {
    return target === SLIDER_TARGETS.main ? this.mainSlideTargets : this.thumbSlideTargets
  }

  dispatchEvent(activatedSlide, target) {
    this.element.dispatchEvent(new CustomEvent('scroll-snap:change', {
      detail: {
        slide: activatedSlide?.target,
        slidesVisible: Array.from(this.currentSlides[target]),
        slidesCount: this.mainSlideTargets.length
      }
    }))
  }

  adjustButtons() {
    if (this.getFirstVisible() === 0) {
      this.prevTargets.forEach(button => button.classList.add(THUMB_NAV_HIDDEN_CLASS))
    } else {
      this.prevTargets.forEach(button => button.classList.remove(THUMB_NAV_HIDDEN_CLASS))
    }

    if (this.getLastVisible() === this.thumbSlideTargets.length - 1) {
      this.nextTargets.forEach(button => button.classList.add(THUMB_NAV_HIDDEN_CLASS))
    } else {
      this.nextTargets.forEach(button => button.classList.remove(THUMB_NAV_HIDDEN_CLASS))
    }
  }
}
