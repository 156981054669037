import { Controller } from '@hotwired/stimulus'
import { Cloudinary } from "cloudinary-core"

export default class extends Controller {
  static values = {
    cloudName: String
  }

  connect() {
    this.cloudinary = new Cloudinary({cloud_name: this.cloudNameValue})
    this.cloudinary.responsive()

    document.querySelectorAll('.modal').forEach((modal) => {
      modal.addEventListener('shown.bs.modal', () => {
        const responsiveImages = modal.querySelectorAll('img.cld-responsive')

        this.cloudinary.cloudinary_update(responsiveImages, {responsive: true})
      })
    })
  }
}
