import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { KeyboardCodes } from "../utils/keyboard"

const SEARCH_ICON_OVERLAPPING_CLASS =
  "top-menu-search__search-icon--overlapping"

export default class extends Controller {
  currentSelectedResultRow = null

  static targets = [
    "icon",
    "form",
    "resultSet",
    "resultRow",
    "queryInput",
    "search",
    "searchContainer",
  ]

  connect = () => {
    document.addEventListener("keyup", this.captureArrowKeys)
  }

  disconnect = () => {
    document.removeEventListener("keyup", this.captureArrowKeys)
  }

  toggleSearch = (event) => {
    event.preventDefault()

    this.queryInputTarget.value = ""
    this.searchContainerTarget.classList.contains("hidden")
      ? this.openSearch()
      : this.closeSearch()
  }

  openSearch = () => {
    this.iconTarget.classList.add(SEARCH_ICON_OVERLAPPING_CLASS)
    this.searchContainerTarget.classList.remove("hidden")

    // ESC
    document.addEventListener("keyup", this.reactToKey(KeyboardCodes.Escape, this.closeSearch))

    // poorman's click outside
    this.searchTarget.addEventListener("click", (event) =>
      event.stopPropagation(),
    )
    document.addEventListener("click", this.closeSearch)

    this.queryInputTarget.focus()
  }

  closeSearch = () => {
    this.iconTarget.classList.remove(SEARCH_ICON_OVERLAPPING_CLASS)
    this.searchContainerTarget.classList.add("hidden")
    this.resultSetTarget.classList.add("hidden")

    document.removeEventListener("keyup", this.reactToKey(KeyboardCodes.Escape, this.closeSearch))

    this.searchTarget.removeEventListener("click", (event) =>
      event.stopPropagation(),
    )
    document.removeEventListener("click", this.closeSearch)
  }

  reactToKey = (keyCode, callback) => (event) => {
    if (event.code === keyCode) {
      event.preventDefault()
      callback()
    }
  }

  typeAhead = (event) => {
    if (event.code === KeyboardCodes.ArrowDown || event.code === KeyboardCodes.ArrowUp) {
      return
    }

    this.resultSetTarget.classList.add("hidden")
    if (event.target.value.length >= 3) {
      this.submitForm()
    }
  }

  captureArrowKeys = (event) => {
    event.preventDefault()

    if (!this.hasResultRowTarget) {
      this.currentSelectedResultRow = null
      return
    }


    if (event.code === KeyboardCodes.ArrowDown) {
      this.currentSelectedResultRow = this.currentSelectedResultRow !== null ? this.nextResultRowIndex() : 0
    }

    if (event.code === KeyboardCodes.ArrowUp) {
      if (this.currentSelectedResultRow === 0 || this.currentSelectedResultRow === null) {
        this.currentSelectedResultRow = null
        this.queryInputTarget.focus()
        return
      } else {
        this.currentSelectedResultRow = this.prevResultRowIndex()
      }
    }

    this.resultRowTargets[this.currentSelectedResultRow].focus()
  }

  nextResultRowIndex = () => {
    if (this.currentSelectedResultRow === null) return 0

    return Math.min(this.currentSelectedResultRow + 1, this.resultRowTargets.length - 1)
  }

  prevResultRowIndex = () => {
    return Math.max(this.currentSelectedResultRow - 1, 0)
  }


  submitForm = debounce(
    () => {
      this.resultSetTarget.classList.remove("hidden")
      const searchingIndicator =
        this.resultSetTarget.dataset.searchingIndicator || "Searching..."
      this.resultSetTarget.innerHTML = `<span class="top-menu-search__searching-indicator">${searchingIndicator}</span>`

      Rails.fire(this.formTarget, "submit")
    },
    333,
    { trailing: true },
  )
}
