import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "submitButton",
    "subscriptionCheckbox",
  ]

  subscribeAndSubmit() {
    this.subscriptionCheckboxTarget.checked = true
    this.subscriptionCheckboxTarget.form.requestSubmit()
  }
}
