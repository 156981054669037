import { Controller } from "@hotwired/stimulus"
import "@selectize/selectize"
import { railsFetch, turboStreamFetch } from "src/utils/http"

export default class extends Controller {
  static targets = [
    "selectize",
    "changeLicenseFrame",
    "payableInfo",
    "assignableInfo",
  ]
  static values = {
    info: Array,
    url: String,
    licenseChangeUrl: String,
    currentPackage: String,
  }


  connect() {
    $(this.selectizeTarget).selectize()
    this.selectedValue = this.selectizeTarget.selectize.getValue()
    this.fetchAvailabilityInfos()
    if (this.licenseChangeUrlValue) {
      this.selectizeTarget.addEventListener("change", this.licenseChangeInfos)
    }
  }

  async fetchAvailabilityInfos() {
    const response = await fetch(
      this.urlValue + "?" + new URLSearchParams({ current_package: this.currentPackageValue }),
    )
    const data = await response.json()
    this.appendInfos(data)
  }

  disconnect() {
    this.selectizeTarget.selectize.destroy()
  }

  setupSelectize = () => {
    const selectizeConfig = {
      onChange: () => this.selectizeTarget.dispatchEvent(new Event("change")),
      create: false,
      allowEmptyOption: true,
      render: {
        item: (data) => {
          return (
            "<div class='item justify-content-between d-inline-flex' style='width: 99%'>" +
            data.text +
            this.prettyAdditionalInfos(data) +
            "</div>"
          )
        },
        option: (data) => {
          return (
            "<div class='justify-content-between d-flex option'>" +
            data.text +
            this.prettyAdditionalInfos(data) +
            "</div>"
          )
        },
      },
    }

    this.selectizeTarget.selectize.destroy()
    $(this.selectizeTarget).selectize(selectizeConfig)
    this.selectizeTarget.selectize.setValue(this.selectedValue)
  }

  appendInfos = (available) => {
    this.infoValue = available
    this.setupSelectize()
  }

  selectWithModal(event) {
    this.selectizeTarget.selectize.setValue(event.target.dataset.value)
  }

  prettyAdditionalInfos(data) {
    let additionalInfos = this.getAdditionalInfos(data)

    if (additionalInfos == "") {
      return ""
    }

    return [additionalInfos].flat().filter(el => { return el != undefined } )
                                   .map((addInfo) => "<span class='additional-infos'>" + addInfo + "</span>").join('')
  }

  getAdditionalInfos(data) {
    if (data.value == "") {
      return ""
    }

    return this.infoValue.find((element) => element["name"] == data.value)["info"]
  }

  toggleDependencies = (data) => {
    let freeLicensesAvailable = this.infoValue.find((element) => element["name"] === data.target.value)["free_licenses_available"]

    this.payableInfoTargets.forEach((e) => e.classList.toggle("hidden", freeLicensesAvailable))
    this.assignableInfoTargets.forEach((e) => e.classList.toggle("hidden", !freeLicensesAvailable))
  }

  licenseChangeInfos = async () => {
    const url = new URL(this.licenseChangeUrlValue)
    url.searchParams.append("selected_license", this.selectizeTarget.selectize.getValue())

    if (this.changeLicenseFrameTarget) {
      this.changeLicenseFrameTarget.classList.add("license-change-infos--busy")
      await turboStreamFetch(url.toString())
      this.changeLicenseFrameTarget.classList.remove("license-change-infos--busy")
    }
  }
}
