import { Application } from '@hotwired/stimulus'
import {sharedDefinitions} from './stimulus_shared_definitions'

export default {
  start: (...additionalDefinitions) => {
    const application = Application.start()

    application.handleError = (error, message, detail) => {
      console.warn(error, message, detail)
    }

    const definitionSets = [sharedDefinitions(), ...additionalDefinitions]
    const definitions = definitionSets.reduce((prev, curr) => prev.concat(curr))
    application.load(definitions)

    return application
  }
}
