import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

const WIDTH_THRESHOLD = 960
const COLLAPSE_COOKIE = 'collapse_sidebar'

export default class extends Controller {
  static classes = ['collapsed', 'toggleRemoved']
  static targets = ['toggle']

  connect() {
    this.initialSidebarState()
    window.addEventListener('resize', this.handleResize.bind(this))
  }


  handleResize() {
    if (!this.hasToggleTarget) return

    this.handleToggleVisibility()
    if (this.shouldCollapseSidebar()) this.collapseSidebar()
    if (this.shouldExpandSidebar()) this.expandSidebar()
  }

  initialSidebarState() {
    if (!this.shouldCollapseSidebar()) return

    this.collapseSidebar()
  }

  shouldCollapseSidebar() {
    const width = window.innerWidth
    return width < WIDTH_THRESHOLD
  }

  shouldExpandSidebar() {
    const width = window.innerWidth
    const sidebarCollapsed = document.body.classList.contains(this.collapsedClass)
    return width > WIDTH_THRESHOLD && sidebarCollapsed &&!this.userPrefersCollapsedSidebar()
  }

  handleToggleVisibility() {
    if (this.hasToggleRemovedClass) {
      window.innerWidth < WIDTH_THRESHOLD ? this.hideToggleButton() : this.showToggleButton()
    }
  }

  userPrefersCollapsedSidebar() {
    const cookie = Cookies.get(COLLAPSE_COOKIE)
    return cookie === 'true' ? true : false
  }

  hideToggleButton () {
    this.toggleTarget.classList.add(this.toggleRemovedClass)
  }

  showToggleButton() {
    this.toggleTarget.classList.remove(this.toggleRemovedClass)
  }

  collapseSidebar() {
    document.body.classList.add(this.collapsedClass)
  }

  expandSidebar() {
    document.body.classList.remove(this.collapsedClass)
  }

  toggle = (event) => {
    event.preventDefault()

    Cookies.set(COLLAPSE_COOKIE, (document.body.classList.toggle(this.collapsedClass)))
  }
}
