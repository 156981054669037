import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {

  connect() {
    this._tomSelect = new TomSelect(this.element, this.configuration())
    this.element['tomSelect'] = this._tomSelect
  }

  configuration() {
    const config = {}

    if (this.element.dataset.disableSearch) {
      config["controlInput"] = null;
    }

    if (this.element.dataset.allowEmpty) {
      config["allowEmptyOption"] = true;
    }

    return config
  }
}
