import "../src/notification"
import "../src/polyfills"
import "../../../../../app/javascript/src/shared/_selectize_patch"
import "../src/utils/countries_i18n"
import "iframe-resizer/js/iframeResizer.contentWindow"

import stimulus from "../src/stimulus"

stimulus.start()

import Rails from "@rails/ujs"

Rails.start()

import * as ActionCable from "@rails/actioncable"

ActionCable.logger.enabled = false

import "../src/channels/ready"

import "@hotwired/turbo-rails"

import "../src/sentry"

import "iframe-resizer/js/iframeResizer.contentWindow.js"

// HACK: to force turbolinks to scroll back to top on navigation, but NOT within storyblok preview
if (!window.location.search.includes("_storyblok")) {
  document.addEventListener("turbo:before-render", (e) => {
    window.scrollTo({ top: 0 })
  })
}

// HACK: to render 500 better_errors page instead of trying to promote to a full page view
if (NEO_ENV === "development") {
  const oldFrameMissing = Turbo.session.frameMissing

  Turbo.session.frameMissing = (async (frame, fetchResponse) => {
    if (fetchResponse.statusCode >= 500) {
      const html = await fetchResponse.responseHTML
      document.querySelector("html").innerHTML = html
    } else {
      console.trace({frame, fetchResponse})
      oldFrameMissing.call(Turbo.session, frame, fetchResponse)
    }
  }).bind(Turbo.session)
}

Turbo.StreamActions.redirect = function() {
  Turbo.visit(this.target)
}
