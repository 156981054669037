import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ['loginBanner', 'privateDataBanner'];

  static values = {
    url: String
  }

  connect() {
    this.app = new window.webex.Application();
    this.app.onReady().then(() => {
      const user = this.app.application.states.user;

      if (this.app.isPrivateDataAvailable) {
        document.addEventListener("turbo:before-fetch-request", function(event) {
          const { headers } = event.detail.fetchOptions
          headers["X-Webex-JWT"] = user.token
          headers["X-Webex-Embedded-App"] = 'boogie yoogie'
        })

        if (this.hasUrlValue) {
          Turbo.visit(this.urlValue, { frame: 'app', action: 'replace' })
        }
      } else {
        this.privateDataBannerTarget.classList.toggle('d-none')
        this.loginBannerTarget.classList.toggle('d-none')
      }
    });
  }

  dial(event) {
    event.preventDefault;
    this.app.openUrlInSystemBrowser(`webextel://${event.currentTarget.dataset.number}`).catch((errorcode) => {
      console.log("Error: ", window.Webex.Application.ErrorCodes[errorcode]);
    })
  }
}
