import { Controller } from "@hotwired/stimulus"
import "@selectize/selectize"
import { railsFetch, turboStreamFetch } from "src/utils/http"

export default class extends Controller {
  static targets = ['selectize']
  static values = {url: String}

  connect() {
    this.loadSyncStates()
    const frame = this.element.querySelector('table').closest('turbo-frame')
    frame.addEventListener('turbo:frame-load', this.loadSyncStates);
  }

  loadSyncStates = () => {
    const numberIds = this.getNumberIds()
    const url = new URL(this.urlValue)

    numberIds.forEach((id) => {
      url.searchParams.append("numbers[]", id)
    })

    turboStreamFetch(url.toString())
  }

  getNumberIds() {
    return Array.from(this.element.querySelectorAll('table .assigned_user turbo-frame')).map((frame) => frame.dataset.numberId)
  }
}
