import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hint', 'indicator']

  connect() {
    document.body.classList.add('full-screen-scroller')
    document.documentElement.classList.add('full-screen-scroller-doc')
    this.navBar = document.querySelector('nav.navigation')
    this.navBarHeight = this.navBar.getBoundingClientRect().height

    document.addEventListener('scroll', this.scrollListener)

    this.element.addEventListener('scroll-snap:change', (event) => {
      this.hintTarget.classList.toggle('hidden', event.detail.slidesVisible[0] !== 0)

      if (!event.detail.slide) return
      this.element.classList.toggle('full-screen-scroller--is-dark', event.detail.slide.classList.contains('common--is-dark'))
      this.element.classList.toggle('full-screen-scroller--indicator-inverted', event.detail.slide.classList.contains('full-screen-scroller-item--indicator-inverted'))
      this.indicatorTarget.classList.toggle('hidden', event.detail.slide.dataset.noIndicator === 'true')
    })
  }

  scrollListener = () => {
    if (document.documentElement.scrollTop > this.navBarHeight && !this.navBar.classList.contains('navigation--mobile-active')) {
      this.navBar.style.top = `-${this.navBarHeight}px`
    } else {
      this.navBar.style.top = '0'
    }
  }

  disconnect() {
    document.body.classList.remove('full-screen-scroller')
    document.removeEventListener('scroll', this.scrollListener)
    document.documentElement.classList.remove('full-screen-scroller-doc')
    this.navBar.style.top = '0'
  }
}
