import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleDependent', 'toggle']

  connect() {
    this.toggleTargets
      .filter(input => this.shouldShow(input))
      .forEach((input) => {
        this.performToggle(input)
    })
  }

  toggleDependent(event) {
    this.performToggle(event.target)
  }

  performToggle(input) {
    const visible = this.shouldShow(input)
    this.toggleDependentTarget.classList.toggle('hidden', !visible)
    this.changeToggleInputs({ disabled: !visible })
  }

  shouldShow(input) {
    if (input.tagName !== "SELECT" && !input.checked) {
      return false
    }

    if (input.dataset.ifgte) {
      return +input.value >= +input.dataset.ifgte
    }

    return input.dataset.if == input.value
  }

  changeToggleInputs(options) {
    for (const input of this.toggleDependentTarget.querySelectorAll('input, select')) {
      input.disabled = options.disabled
    }
  }
}
