import { Controller } from "@hotwired/stimulus"
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["item", "total"]

  itemTargetConnected() {
    this.totalTarget.innerText = I18n.t('javascript.console.waiting_calls.calls', { count: this.count })
  }

  get count() {
    return this.itemTargets.reduce((sum, item) => {
      return sum + parseInt(item.dataset.value);
    }, 0);
  }
}

