import { Controller  } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'button'];
  static values = {
    target: String
  }

  matchesTarget = (e) => {
    var isMatching = this.inputTarget.value.trim() === this.targetValue.trim();
    this.buttonTarget.classList.toggle('disabled', !isMatching)
    this.buttonTarget.disabled = !isMatching
  }
}
