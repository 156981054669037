import { Controller } from "@hotwired/stimulus"
import isEqual from 'lodash/isEqual'

export default class extends Controller {
  static targets = ['button', 'input', 'checkbox']

  connect() {
    if(this.hasButtonTarget) {
      this.toggleButton()
    }
  }

  inputTargetConnected(target) {
    target.addEventListener('change', this.inputChanged)
    target.addEventListener('keyup', this.inputChanged)
    this.initState = this.storeInputValues()
    this.currentState = this.storeInputValues()
  }

  checkboxTargetConnected(target) {
    target.addEventListener('change', this.inputChanged)
    this.initState = this.storeInputValues()
    this.currentState = this.storeInputValues()
  }

  storeInputValues() {
    const storage = []
    this.inputTargets.forEach(input => {
      storage.push(input.value)
    })
    this.checkboxTargets.forEach(input => {
      storage.push(input.checked)
    })
    return storage
  }

  disconnect() {
    this.inputTargets.forEach(input => {
      input.removeEventListener('change', this.inputChanged)
      input.removeEventListener('keyup', this.inputChanged)
    })
    this.checkboxTargets.forEach(input => {
      input.removeEventListener('change', this.inputChanged)
    })
  }

  toggleButton() {
    this.buttonTarget.classList.toggle('d-none', this.formHasChanged())
  }

  formHasChanged = () =>
    isEqual(JSON.stringify(this.initState), JSON.stringify(this.currentState))

  inputChanged = () => {
    this.currentState = this.storeInputValues()
    this.toggleButton()
  }
}
