import { Controller } from "@hotwired/stimulus"

function isCurrentStep(frame, index) {
  return !!frame.querySelector(`button[type="submit"][name="step"][value="${index + 1}"]`)
}

export default class extends Controller {
  static targets = ["frame"]

  static classes = [
    "active",
    "past",
    "future ",
  ]

  connect() {
    super.connect()

    this.updateFrames(this.frameTargets)
    this._observer = new MutationObserver(this._observed)
    this._observer.observe(this.element, { subtree: true, childList: true })
  }

  disconnect() {
    super.disconnect()
    this._observer.disconnect()
  }

  updateFrames(frames) {
    const currentFrame = frames.find(isCurrentStep)
    if (!currentFrame) return
    const currentStep = +currentFrame.dataset.step

    frames.forEach(frame => {
      this._stepUpdated(frame, currentStep)
    })
  }

  _observed = () => {
    this.updateFrames(this.frameTargets)
  }

  _stepUpdated(frame, currentStep) {
    const frameStep = +frame.dataset.step
    const container = frame.closest("[data-step-container]")
    const isCurrent = frameStep == currentStep
    const isFuture = frameStep > currentStep

    this._updateIndicators(container, frameStep, currentStep)
    container.classList.toggle(this.activeClass, isCurrent)
    container.classList.toggle(this.pastClass, frameStep < currentStep)
    container.classList.toggle(this.futureClass, isFuture)
  }

  _updateIndicators(container, frameStep, currentStep) {
    const icon = container.querySelector(".form-steps__icon")
    const number = container.querySelector(".form-steps__number")

    if (!icon || !number) return

    icon.classList.toggle("d-none", frameStep >= currentStep)
    number.classList.toggle("d-none", frameStep < currentStep)
  }
}
