import { Controller } from '@hotwired/stimulus'
import Dropzone, { DropzoneMockFile } from "dropzone"
import { uniq } from "lodash"
import Rails from "@rails/ujs"


export default class extends Controller {
  static values = {
    uploadUrl: String,
    paramName: String,
    acceptedFiles: Array,
    cachedFiles: Array,
  }

  static targets = ["cache", "dropzone", "previews"]

  connect() {
    this._dropzone = new Dropzone(this.dropzoneTarget, {
      createImageThumbnails: false,
      url: this.uploadUrlValue,
      paramName: this._getArrayParamName, // The types are wrong / missing the function-type
      acceptedFiles: (this.acceptedFilesValue || []).join(","),
      uploadMultiple: true,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      previewTemplate: this.element.querySelector("template").innerHTML,
      previewsContainer: this.previewsTarget,
      clickable: [this.dropzoneTarget, ...this.dropzoneTarget.children],  // Otherwise the text within is not clickable, lol
    })

    this._dropzone.on("success", (file, newCacheNames) => {
      const oldCacheNames = this._getOldCacheNames()

      this.cacheTarget.value = JSON.stringify(uniq([...oldCacheNames, ...newCacheNames]))
    })

    this.cachedFilesValue.forEach(file => {
      this._dropzone.emit("addedfile", file);
      this._dropzone.emit("complete", file);
      this._dropzone.emit("success", file, []);
    })
  }

  _getOldCacheNames() {
    try {
      return JSON.parse(this.cacheTarget.value)
    } catch (e) {
      // It might be empty or junk-y
      return []
    }
  }

  disconnect() {
    this._dropzone.destroy()
    delete this._dropzone
  }

  _getArrayParamName = () => {
    // The documentation lies, it does not append `[]`, but `[0]` etc.
    return `${this.paramNameValue}[]`
  }
}
