import { Controller } from '@hotwired/stimulus'

const ACTIVE_CLASS = 'scroll-indicators__item--active'

export default class extends Controller {
  static targets = ['indicator', 'bar']

  connect() {
    this.element.addEventListener('scroll-snap:change', this.scrolled)
    this.updateIndicators({slidesVisible: [0]}) // Make sure fist slide is active by default
  }

  disconnect() {
    this.element.removeEventListener('scroll-snap:change', this.scrolled)
  }

  scrolled = (event) => {
    this.updateIndicators(event.detail)
    this.updateBar(event.detail)
  }

  updateIndicators({slidesVisible}) {
    if (!this.indicatorTargets.length || !slidesVisible.length) return

    this.indicatorTargets.forEach(indicatorTarget => {
      indicatorTarget.classList.remove(ACTIVE_CLASS)
    })

    this.indicatorTargets[slidesVisible[0]].classList.add(ACTIVE_CLASS)
  }

  updateBar({slidesVisible, slidesCount}) {
    if (!this.hasBarTarget) return

    const width = this.barTarget.getBoundingClientRect().width
    const widthPerItem = width / slidesCount
    const itemWidth = widthPerItem * slidesVisible.length
    const offset = widthPerItem * Math.min(...slidesVisible)

    const barItem = this.barTarget.firstElementChild

    barItem.style.width = `${itemWidth}px`
    barItem.style.left = `${offset}px`
  }
}
