import { Controller } from '@hotwired/stimulus'
import range  from 'lodash/range'

const thresholdStep = 0.1

function addTransitions(element, ...transitions) {
  const existing = element.style.transition ? element.style.transition.split(',') : []
  existing.push(...transitions)
  element.style.transition = existing.join(', ')
}

class BaseEffect {
  triggered = false
  initial = true

  constructor(element, config, trigger, transition) {
    this.element = element
    this.config = config
    this.trigger = trigger
    this.only_once = config.only_once
    this.threshold = (this.config.threshold || 0) / 100
    this.transition = transition
  }

  clampByThreshold(ratio) {
    if (ratio < this.threshold) return 0
    return this.threshold + (ratio) * (1 - this.threshold)
  }

  evaluate(entry) {
    const ratio = Math.round(entry.intersectionRatio / thresholdStep) * thresholdStep

    if (this.only_once && this.triggered) return

    this.applyRatio(ratio)

    if (this.trigger === 'leave' && ratio === 0 && !this.initial)
      this.triggered = true

    if (this.trigger === 'enter' && ratio === 1 && !this.initial)
      this.triggered = true

    this.initial = false
  }

  applyRatio() {
    console.warn('Effect not implemented')
  }
}

class FadeOutEffect extends BaseEffect {
  constructor() {
    super(...arguments)

    addTransitions(this.element, `opacity ${this.transition}`)
  }

  applyRatio(ratio) {
    ratio = this.clampByThreshold(ratio)
    this.element.style.opacity = this.trigger === 'leave' ? ratio : 1 - ratio
  }
}

class TransformEffect extends BaseEffect {
  constructor() {
    super(...arguments)

    this.childElement = this.element.firstElementChild
    addTransitions(this.childElement, `transform ${this.transition}`)

    if (this.config.translate_x) {
      this.translateX = Number.parseInt(this.config.translate_x)
    }
    if (this.config.translate_y) {
      this.translateY = Number.parseInt(this.config.translate_y)
    }
    if (this.config.scale_x) {
      this.scaleX = Number.parseInt(this.config.scale_x) / 100
    }
    if (this.config.scale_y) {
      this.scaleY = Number.parseInt(this.config.scale_y) / 100
    }
  }

  applyRatio(ratio) {
    const transform = []
    const active = this.trigger === 'leave' ? ratio < this.threshold : ratio > this.threshold

    if (this.translateX) transform.push(`translateX(${(active ? this.translateX : 0)}%)`)
    if (this.translateY) transform.push(`translateY(${(active ? this.translateY : 0)}%)`)
    if (this.scaleX) transform.push(`scaleX(${1 + (active ? this.scaleX : 0)})`)
    if (this.scaleY) transform.push(`scaleY(${1 + (active ? this.scaleY : 0)})`)

    this.element.firstElementChild.style.transform = transform.join(' ')
  }
}

const effects = {
  effect_fade_out: FadeOutEffect,
  effect_transform: TransformEffect
}

export default class extends Controller {

  static values = {
    config: Object,
  }

  connect() {
    this.observer = new IntersectionObserver(this.observed, {
      root: null,
      threshold: range(0, 1 + thresholdStep, thresholdStep)
    })

    this.observer.observe(this.element)

    this.effects = []

    this.configValue.onLeave.forEach((config) => {
      const effectClass = effects[config.component]

      if (effectClass) {
        this.effects.push(new effectClass(this.element, config, 'leave', this.configValue.transition))
      }
    })

    this.configValue.onEnter.forEach((config) => {
      const effectClass = effects[config.component]

      if (effectClass) {
        this.effects.push(new effectClass(this.element, config, 'enter', this.configValue.transition))
      }
    })
  }

  disconnect() {
    this.observer.disconnect()
  }

  observed = (entries) => {
    entries.forEach((entry) => {
      this.effects.forEach((effect) => effect.evaluate(entry))
    })
  }
}
