import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash"
import { currencyFormatter } from "src/utils/money"
import { turboStreamFetch } from "src/utils/http"

export default class extends Controller {
  static values = {
    recurringPrice: Number,
    setupFee: Number,
    currency: String,
    citiesByCountryUrl: String,
    location: String,
  }
  static targets = [
    'countryAlpha2',
    'recurringPriceDisplay',
    'setupFeeDisplay',
    'quantity',
  ]

  connect = async () => {
    const country_alpha2 = this.countryAlpha2Target.value

    this.changeAmount()
    if (!isEmpty(country_alpha2)) {
      await this.fetchCountriesByAlpha2(country_alpha2, this.locationValue)
    }
  }

  countryChanged = () => {
    const country_alpha2 = this.countryAlpha2Target.value

    this.fetchCountriesByAlpha2(country_alpha2, "")
  }

  fetchCountriesByAlpha2 = async (country_alpha2, location_id) => {
    const url = new URL(this.citiesByCountryUrlValue)
    url.searchParams.append("country_alpha2", country_alpha2)
    url.searchParams.append("location_id", location_id)

    await turboStreamFetch(url.toString())
  }

  changeAmount = async () => {
    const amount = this.quantityTarget.valueAsNumber || 0

    const recurringAmount = amount * this.recurringPriceValue
    this.recurringPriceDisplayTarget.innerHTML = currencyFormatter(this.currencyValue).format(recurringAmount)

    const setupFee = amount * this.setupFeeValue
    this.setupFeeDisplayTarget.innerHTML = currencyFormatter(this.currencyValue).format(setupFee)
  }
}
