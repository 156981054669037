import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slide', 'indicator', 'next', 'prev']

  connect() {
    if (this.slideTargets.length < 1) return

    this.setupIntersectionObserver()

    this.indicatorTargets.forEach((indicator, index) => {
      indicator.addEventListener('click', () => {
        this.scrollTo(index)
      })
    })

    this.nextTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.scrollRight()
      })
    })

    this.prevTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.scrollLeft()
      })
    })

  }

  scrollTo(index) {
    this.slideTargets[index].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'})
  }

  adjustButtons() {
    if (this.getFirstVisible() === 0) {
      this.prevTargets.forEach(button => button.classList.add('disabled'))
    } else {
      this.prevTargets.forEach(button => button.classList.remove('disabled'))
    }

    if (this.getLastVisible() === this.slideTargets.length - 1) {
      this.nextTargets.forEach(button => button.classList.add('disabled'))
    } else {
      this.nextTargets.forEach(button => button.classList.remove('disabled'))
    }
  }

  scrollRight() {
    const lastVisible = this.getLastVisible()

    if (lastVisible && lastVisible + 1 < this.slideTargets.length)
      this.scrollTo(lastVisible + 1)
  }

  getLastVisible() {
    return Math.max(...this.currentSlides)
  }

  scrollLeft() {
    const firstVisible = this.getFirstVisible()

    if (firstVisible && firstVisible > 0)
      this.scrollTo(firstVisible - 1)
  }

  getFirstVisible() {
    return Math.min(...this.currentSlides)
  }

  disconnect() {
    this.observer?.disconnect()
  }

  setupIntersectionObserver() {
    let root = this.element === document.body ? null : this.element
    this.observer = new IntersectionObserver(this.callback.bind(this), {
      root: root,
      threshold: 0.5
    })

    this.slideTargets.forEach(slideTarget => {
      this.observer.observe(slideTarget)
    })

    this.currentSlides = new Set()
  }

  callback(entries, _observer) {
    if (!entries.length) return

    let activatedSlide
    entries.forEach((entry) => {
      let entryIndex = this.slideTargets.indexOf(entry.target)
      if (entry.isIntersecting) {
        if (!activatedSlide || entry.intersectionRatio > activatedSlide.intersectionRatio) {
          activatedSlide = entry
        }

        this.currentSlides.add(entryIndex)
      } else {
        this.currentSlides.delete(entryIndex)
      }
    })

    this.adjustButtons()
    this.preloadImages()
    this.dispatchEvent(activatedSlide)
  }

  dispatchEvent(activatedSlide) {
    this.element.dispatchEvent(new CustomEvent('scroll-snap:change', {
      detail: {
        slide: activatedSlide?.target,
        slidesVisible: Array.from(this.currentSlides),
        slidesCount: this.slideTargets.length
      }
    }))
  }

  preloadImages() {
    const lastVisible = this.getLastVisible()

    this.preloadImage(lastVisible + 1)
    this.preloadImage(lastVisible + 2)
  }

  preloadImage(index) {
    if (this.slideTargets[index]) {
      this.slideTargets[index].querySelectorAll('img[loading]').forEach((img) => {
        img.removeAttribute('loading')
      })
    }
  }
}
