import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  get frame() {
    return this.element;
  }

  connect() {
    super.connect()
    this.frame.addEventListener('turbo:frame-render', this.frameLoaded)
  }

  disconnect() {
    super.disconnect()
    this.frame.removeEventListener('turbo:frame-render', this.frameLoaded)
  }

  frameLoaded = (ev) => {
    // Since we're not targeting the _top frame, we have to update the browser URL manually
    // Let's also remove the ugly empty params
    const url = new URL(ev.detail.fetchResponse.location)
    for(const key of Array.from(url.searchParams.keys())) {
      if (!url.searchParams.get(key)) {
        url.searchParams.delete(key)
      }
    }

    window.history.pushState({}, '', url.toString())
  }
}
