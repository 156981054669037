import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['progressBar', 'label'];

  static values = {
    expectedDuration: Number,
    startTimestamp: Number,
    intervalSeconds: { type: Number, default: 5 },
    maxPercentage: { type: Number, default: 99 },
  };

  connect() {
    this.update();

    if (this.percentage >= 99) return;

    this.refreshTimer = window.setInterval(() => {
      this.update();
    }, this.intervalSeconds * 1000);
  }

  disconnect() {
    window.clearInterval(this.refreshTimer);
  }

  update() {
    this.updateLabel();
    this.updateProgressBar();
  }

  updateProgressBar() {
    this.progressBarTarget.style.width = `${this.percentage}%`;
    this.progressBarTarget.setAttribute('aria-valuenow', this.percentage);
  }

  updateLabel() {
    if (!this.hasLabelTarget) return;

    const label = Intl.NumberFormat(document.documentElement.lang, {
      style: 'percent',
    }).format(Math.floor(this.percentage) / 100);

    this.labelTarget.innerText = label;
  }

  // Since we remove the progress bar completely when the
  // step is complete, we stop at 99% 😈
  get percentage() {
    const percentage = ((this.currentTimestamp - this.startTimestampValue) * 100) / this.expectedDurationValue;

    return Math.min(percentage, this.maxPercentageValue);
  }

  get currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }
}
