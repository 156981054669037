import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de";
import { French } from "flatpickr/dist/l10n/fr";
import I18n from "i18n-js"

export default class extends Controller {
  static targets = ["date"]

  initialize() {
    const lang = document.documentElement.lang

    if (lang === "de") {
      flatpickr.localize(German)
    } else if (lang === "fr") {
      flatpickr.localize(French)
    }
  }

  connect() {
    this.dateTargets.forEach((dateTarget) => this.setupDateSelector(dateTarget))
  }

  disconnect() {
    this.dateTargets.forEach((dateTarget) => flatpickr(dateTarget, {}).destroy())
  }

  setupDateSelector = (target) => {

    const options = {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: I18n.t("javascript.flatpickr.formats.date"),
      disable: [],
    }

    if (target.dataset["withoutWeekends"]) {
      options.disable.push((date) => date.getDay() === 6 || date.getDay() === 0)
    }
    if (target.dataset["withoutAnnualShutdown"]) {
      // Annual shutdown from Dec 22 to Jan 4
      options.disable.push((date) => (date.getMonth() + 1 === 12 && date.getDate() >= 22) || (date.getMonth() + 1 === 1 && date.getDate() <= 4))
    }
    if (target.dataset["withoutFridays"]) {
      options.disable.push((date) => date.getDay() === 5)
    }
    if (target.dataset["asRange"]) {
      options.mode = "range"
      options.defaultDate = target.dataset["dates"].split(",")
      options.locale = { rangeSeparator: " / " }
    }

    flatpickr(target, options)
  }
}
