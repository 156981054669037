import Rails from "@rails/ujs"
import * as Turbo from "@hotwired/turbo"

export function csrfToken() {
  return Rails.csrfToken()
}

export function railsFetch(url, init) {
  let headers = init?.headers || {}

  const method = init?.method?.toUpperCase() || "GET"
  if (method !== "GET" && method !== "HEAD") {
    headers = {
      ...headers,
      "X-CSRF-Token": csrfToken(),
    }
  }

  return fetch(
    url,
    {
      ...init,
      headers: headers,
    },
  )
}

export async function turboStreamFetch(url, init) {
  const response = await railsFetch(url, init)

  if (response.ok) {
    Turbo.renderStreamMessage(await response.text())
  }
}
