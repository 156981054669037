import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    name: String,
    email: String,
    ref: String,
    subject: String,
    description: String,
  }

  openTicketForm(event) {
    event.preventDefault()

    this.loadDependencies().then(() => {
      window.FreshworksWidget('hide', 'launcher')
      window.FreshworksWidget('identify', 'ticketForm', {
        name: this.nameValue,
        email: this.emailValue,
        subject: this.subjectValue,
        description: this.descriptionValue,
        custom_fields: {
          cf_customer_ref: this.refValue
        }
      })
      window.FreshworksWidget('disable', 'ticketForm', ['custom_fields.cf_customer_ref'])
      window.FreshworksWidget('setLabels', { [I18n.locale]: I18n.t('javascript.freshdesk_widget') })
      window.FreshworksWidget('open', 'ticketForm')

      // Turbolinks/Turbo breaks freshdesk, so lets make the next navigation "real"
      document.body.setAttribute(`data-turbo${window.Turbolinks ? 'links' : ''}`, 'false')
    })
  }

  loadDependencies() {
    // https://support.placetel.de/a/admin/widgets/15000000341
    const src = 'https://euc-widget.freshworks.com/widgets/15000000341.js';

    return new Promise((resolve, reject) => {
      if (window.FreshworksWidget) {
        resolve();
      } else {
        // https://developers.freshdesk.com/widget-api
        window.fwSettings = { 'widget_id': 15000000341, 'locale': I18n.locale.slice(0, 2) };
        if ('function' != typeof window.FreshworksWidget) {
          var n = function () { n.q.push(arguments); };
          (n.q = []), (window.FreshworksWidget = n);
        }

        const script   = document.createElement('script');
        script.src     = src;
        script.type    = 'text/javascript';
        script.async   = false;
        script.onload  = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  }
}
