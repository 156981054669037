import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import $ from "jquery"
import "@selectize/selectize"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ['input']
  static values = {
    carrierUrl: String
  }

  connect() {
    $(this.inputTarget).selectize({
      persist: false,
      createOnBlur: true,
      create: value => ({ value, text: value }),
      onChange: value => this.updateAside(value)
    })
  }

  disconnect() {
    $(this.inputTarget)[0].selectize.destroy()
  }

  updateAside = debounce(async (value) => {
    if (!this.carrierUrlValue) return

    const url = this.carrierUrlValue + `?carrier=${encodeURIComponent(value)}`
    const response = await fetch(url)

    Turbo.renderStreamMessage(await response.text())
  }, 100)
}
