import { Controller } from '@hotwired/stimulus'
import Carousel from 'bootstrap/js/dist/carousel'

export default class extends Controller {
  static targets = ['next', 'prev', 'submit']

  connect() {
    this.carousel = new Carousel(this.element, { interval: false })

    this.items = this.element.querySelectorAll('.carousel .carousel-item')

    this.nextTarget.addEventListener('click', this.next)
    this.prevTarget.addEventListener('click', this.prev)
    this.handleButtons()
  }

  next = () => {
    this.carousel.next()
    this.handleButtons()
  }

  prev =() => {
    this.carousel.prev()
    this.handleButtons()
  }

  getActiveIndex = () => {
    return Array.from(this.items).findIndex((item) => item.classList.contains('active'))
  }

  handleButtons = () => {
    if (this.getActiveIndex() === 0) {
      this.prevTarget.classList.add('disabled')
    } else {
      this.prevTarget.classList.remove('disabled')
    }
    if (this.getActiveIndex() === this.items.length - 1) {
      this.nextTarget.classList.add('d-none')
      this.submitTarget.classList.remove('d-none' )
    } else {
      this.nextTarget.classList.remove('d-none')
      this.submitTarget.classList.add('d-none' )
    }
  }
}
