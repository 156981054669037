import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.checkedClass = this.element.getAttribute('data-checked-class')
    this.input = this.element.querySelector('input[type=radio],input[type=checkbox]')
    this.relevantInputs().forEach((inp) => {
      inp.addEventListener('change', () => this.updateVisualState())
    })
    this.updateVisualState()
  }

  updateVisualState() {
    if (!this.checkedClass || !this.input) return

    if (this.input.checked && !this.element.classList.contains(this.checkedClass)) {
      this.element.classList.add(this.checkedClass)
    } else if (!this.input.checked) {
      this.element.classList.remove(this.checkedClass)
    }
  }

  /**
   * Returns the current checkbox element if the input is a checkbox, or all sibling
   * radio button elements.
   */
  relevantInputs() {
    if (!this.input) return []
    if (this.input.getAttribute('type') === 'checkbox') return [this.input]

    let container = this.input.closest('fieldset') || this.input.closest('form')
    if (!container) return []

    return container.querySelectorAll('input[type=radio]')
  }
}
