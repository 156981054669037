import { Controller } from "@hotwired/stimulus"
import isEqual from "lodash/isEqual"

export default class extends Controller {

  get form() {
    return this.element.closest("form")
  }

  connect() {
    this._initialFormData = new FormData(this.form)
    this._applyButtonState()

    this.form.addEventListener("change", this.formInputChanged)
  }

  disconnect() {
    this.form?.removeEventListener("change", this.formInputChanged)
  }

  formInputChanged = (evt) => {
    this._applyButtonState()
  }

  _applyButtonState() {
    if (this._formHasChanges()) {
      this.element.removeAttribute("disabled")
    } else {
      this.element.setAttribute("disabled", "")
    }
  }

  _formHasChanges() {
    const current = new FormData(this.form)

    const keys = new Set([...this._initialFormData.keys(), ...current.keys()])

    for (const key of keys) {
      const initialValue = this._initialFormData.getAll(key)
      const currentValue = current.getAll(key)

      if (!isEqual(initialValue, currentValue))
        return true
    }

    return false
  }
}
