import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radioInput', 'textInput']

  connect() {
    this.showCollapseTextInput();
  }

  showCollapseTextInput() {
    if (this.radioInputTarget.checked) {
      this.textInputTarget.classList.add('show');
      this.textInputTarget.querySelector('textarea').required = true;
    } else {
      this.textInputTarget.classList.remove('show');
      this.textInputTarget.querySelector('textarea').required = false;
    }
  }
}
