import { Controller } from "@hotwired/stimulus"
import { handleMethod } from "@rails/ujs"
import * as Turbo from "@hotwired/turbo"
import { Dropdown } from "bootstrap5"

export default class extends Controller {
  static values = {
    currentCountry: String,
    changeUrl: String,
  }
  static targets = ["countryDetail"]

  get currentCountry() {
    return this._currentCountry || this.currentCountryValue
  }

  get dropdownToggle() {
    return this.element.querySelector(".dropdown-toggle")
  }

  connect() {
    this._dropdown = new Dropdown(this.dropdownToggle)

    this.applyState()
  }

  selected(event) {
    this._currentCountry = event.target.dataset.country

    this._dropdown.hide()

    this.applyState()
  }

  submit(evt) {
    // Need to turn off turbo for this. Re-enables itself
    // automatically after the redirect
    Turbo.session.drive = false
    handleMethod.apply(evt.currentTarget, [evt])
  }

  applyState() {
    for (let detail of this.countryDetailTargets) {
      const isSelected = detail.dataset.country === this.currentCountry
      detail.classList.toggle("hidden", !isSelected)

      if (isSelected) {
        this.setDropdownLabel(detail.querySelector("template.button-label").innerHTML)
      }
    }
  }

  setDropdownLabel(label) {
    this.dropdownToggle.innerHTML = label
  }
}
