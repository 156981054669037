import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { orders: Object, orderItems: Object };

  connect() {
    const orders = this.ordersValue;
    const orderItems = this.orderItemsValue;

    Object.keys(orders).forEach((order_id) => {
      this.pushDataLayer(orders[order_id], orderItems[order_id]);
    });
  }

  pushDataLayer(actionField, products) {
    if (typeof window.dataLayer === "undefined") return;

    window.dataLayer.push({ ecommerce: null });
    const data = {
      event: "purchase",
      ecommerce: {
        ...actionField,
        items: products
      }
    }
    window.dataLayer.push(data);
  }
}
