import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['clock']

  connect() {
    this.timer = setInterval(this.updateTimer.bind(this), 1000)
  }

  disconnect() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  }

  updateTimer() {
    this.clockTargets.forEach(clock => {
      var date    = new Date(0);
      var started = parseInt(clock.dataset.started)
      date.setSeconds(Date.now() / 1000 - started);
      var timeString = date.toISOString().substr(14,5);

      clock.innerText = timeString
    });
  }
}
