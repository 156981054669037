import mainAppStimulus from "../../../../../app/javascript/src/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Sentry from "@sentry/browser"
import { addNamespaceToDefinition } from "src/stimulus_shared_definitions"
import { bootstrap5Stimulus } from "./bootstrap5"

function shopDefinitions() {
  return definitionsFromContext(require.context("./controllers", true, /_controller\.(js|ts)$/))
    // TODO: (matheise) remove namespace
    .map(addNamespaceToDefinition("shop"))
}

function shopComponentDefinitions() {
  return definitionsFromContext(require.context("./components", true, /_controller\.(js|ts)$/))
    // TODO: (matheise) remove namespace
    .map(addNamespaceToDefinition("shop"))
}

export default {
  shopDefinitions,
  shopComponentDefinitions,
  start: () => {
    const application = mainAppStimulus.start(shopDefinitions(), shopComponentDefinitions())
    application.handleError = (error, message, detail) => {
      Sentry.captureException(error, detail)
      console.error(error, message, detail)
    }

    bootstrap5Stimulus()

    return application
  },
}
