import { Controller } from "@hotwired/stimulus"
import some from "lodash/some"

const HIDE_CLASS = "navigation__section--hidden-mobile"
const MOBILE_ACTIVE_CLASS = "navigation--mobile-active"
const ICON_ACTIVE_CLASS = "menu-icon--active"
const ITEM_ACTIVE_CLASS = "navigation__item--active"
const WITH_SUB_ROW_CLASS = "navigation__item--with-sub-row"
const NAV_SUB_ROW_OPEN_CLASS = "navigation-open"

export default class extends Controller {
  static targets = [
    "menuIcon",
    "toggable",
  ]
  isOpen = false

  get itemsWithSubRow() {
    return this.element.querySelectorAll(`.${WITH_SUB_ROW_CLASS}`)
  }

  connect() {
    this.itemsWithSubRow.forEach((item) => {
      if (this._anySubItemActive(item)) {
        item.classList.add(ITEM_ACTIVE_CLASS)
      }

      item.addEventListener("click", () => this.toggleSubRow(item))
    })

    this._toggleBodyClass()
    this._adjustPadding()
    window.addEventListener("resize", () => this._adjustPadding())
  }

  _anyRowOpen() {
    return some(this.itemsWithSubRow, (item) => item.classList.contains(ITEM_ACTIVE_CLASS))
  }

  _anySubItemActive(item) {
    return !!item.querySelector(`.${ITEM_ACTIVE_CLASS}`)
  }

  mobileToggle() {
    this.isOpen = !this.isOpen
    return this.applyState()
  }

  applyState() {
    this.element.classList.toggle(MOBILE_ACTIVE_CLASS, this.isOpen)
    this.toggableTargets.forEach((target) => {
      target.classList.toggle(HIDE_CLASS, !this.isOpen)
    })

    this.menuIconTarget.classList.toggle(ICON_ACTIVE_CLASS, this.isOpen)
  }

  toggleSubRow(toggledItem) {
    if (toggledItem.classList.contains(ITEM_ACTIVE_CLASS)) return

    this.itemsWithSubRow.forEach((other) => {
      other.classList.toggle(ITEM_ACTIVE_CLASS, toggledItem === other)
    })

    this._toggleBodyClass()
    this._adjustPadding()
  }

  _toggleBodyClass() {
    window.document.body.classList.toggle(NAV_SUB_ROW_OPEN_CLASS, this._anyRowOpen())
  }

  _adjustPadding() {
    const navList = this.element.querySelector(".navigation__section--nav-list")
    if (!navList) return
    const paddingLeft = navList.getBoundingClientRect().left
    this.element.querySelectorAll(".navigation__row").forEach((row) => {
      row.style.paddingLeft = paddingLeft + "px"
    })
  }
}
