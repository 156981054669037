import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

export function addNamespaceToDefinition(namespace) {
  return function (definition) {
    return {
      ...definition,
      identifier: `${namespace}--${definition.identifier}`,
    }
  }
}

export function sharedDefinitions() {
  return definitionsFromContext(require.context('../controllers/shared', true, /_controller\.js$/))
}
