import { Controller  } from "@hotwired/stimulus"

export default class extends Controller {
  scroll(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const hash = event.target.hash
    if (!hash) return
    const targetElement = document.getElementById(hash.split('#')[1])
    if (!targetElement) return

    // If the user prefers reduced motion, do not slide them around
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
    const behavior = prefersReducedMotion ? 'auto' : 'smooth'

    targetElement.scrollIntoView({ behavior, block: "start", inline: "nearest" })
  }
}
